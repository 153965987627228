import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { FailedSignUpCases } from 'app/admin/users/failed-sign-up/constants';
import { ICustomer } from 'app/models/invoice.model';
import { informationUrl } from 'app/shared/helpers/methods';
import { FilterService } from 'app/shared/services/filter.service';
import { ContentTableTypes } from '../constants/view-mobile.constant';
import { CustomerInfoAlertComponent } from '../customer/customers/customer-info-alert/customer-info-alert.component';
import { TypeOfProgressBar } from '../progress/invoices.progress.component';

enum UserTypes {
  user = 'User',
  dataUser = 'Data-User',
  service = 'Service',
  openChannel = 'Open Channel',
  reply = 'Reply',
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  readonly contentTableTypes = ContentTableTypes;
  readonly TypeOfProgressBar = TypeOfProgressBar;
  public userTypes = UserTypes;
  public curentlyRoute = this.router.url;

  @Input() pointOfTable: any[] = [];
  @Input() data;
  @Input() isAdmin: boolean;
  @Input() loading: boolean;
  @Input() messageForEmptyTable: string;

  @Output() sort = new EventEmitter();
  @Output() emitDataOnClickOne = new EventEmitter();
  @Output() emitDataOnClickTwo = new EventEmitter();

  @Output() emitDataForRowSelectMethod = new EventEmitter();
  @Output() emitDataForCheckParamMethod = new EventEmitter();
  @Output() emitDataForCheckDisabledMethod = new EventEmitter();

  public subscriptions = new Subscription();

  constructor(
    public filterService: FilterService,
    public translate: TranslateService,
    public modalService: NgbModal,
    public router: Router
  ) {}

  ngOnInit(): void {}

  sortCol(event) {
    this.sort.emit(event);
  }

  emitDataOne(data) {
    this.emitDataOnClickOne.emit(data);
  }

  emitDataTwo(data) {
    this.emitDataOnClickTwo.emit(data);
  }

  stageOfFailedCase = (caseType: string) => FailedSignUpCases[caseType];

  infoUrl(id) {
    const url = informationUrl(id, this.router.url);
    this.router.navigate([url]);
  }

  rowSelect(id: number, checked: boolean) {
    this.emitDataForRowSelectMethod.emit({ id, checked });
  }

  checkParam(id: number) {
    this.emitDataForCheckParamMethod.emit(id);
  }

  checkDisabled(company: any, checked: boolean, field: string) {
    this.emitDataForCheckDisabledMethod.emit({ company, checked, field });
  }

  modalCustomerInfo(customer: ICustomer) {
    const customerModal = this.modalService.open(CustomerInfoAlertComponent);
    customerModal.componentInstance.customer = customer;
  }
}
