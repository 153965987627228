<main>
  <div class="d-flex justify-content-end mb-1 mt-4">
    <div class="mr-4" (click)="closeModal()">
      <span class="close-modal"></span>
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <img src="../../../../assets/img/logos/esynpay.png" width="50%" alt="eSyn Finance ODF">
  </div>
  <h1> {{ 'Get paid early with eSynPay' | translate }}! </h1>
  <h4> {{ 'eSynPay in your eSynCloud now' | translate }}! </h4>
  <p>
    {{ 'eSynCloud offers trusted suppliers the option to receive early invoice payments.' | translate }}
  </p>
  <p> {{ 'Strengthen your liquidity today.' | translate }} </p>
  <div class="mt-4 d-flex justify-content-center">
    <i>
      {{ 'The eSynPay agreement has to be signed by a person with signing rights in your company' | translate }}.
    </i>
  </div>
  <div class="mt-4 mb-2 d-flex justify-content-center">
    <button type="button" class="mr-1 btn btn-primary" (click)="toOdfAlertMessage()">
      <span class="text-white"> {{ 'Learn more about eSynPay here' | translate | uppercase }} </span>
    </button>
  </div>
  <div>
    <h4 class="header-second">
      {{ 'Activate eSynPay in your eSynCloud!' | translate }}
    </h4>
    <h4 class="header-second">
      {{ 'Start receiving instant payments' | translate }}
    </h4>
    <div *ngIf="shareContract === ODF_SIGN_CHECKER.CAN_SIGN; else shareBlock"
      class="mt-4 mb-2 d-flex justify-content-center">
      <button type="button" class="mr-1 btn btn-primary" (click)="toSignUp()">
        <span class="text-white"> {{ 'Sign up contract' | translate | uppercase }} </span>
      </button>
      <button type="button" class="ml-1 btn btn-primary" (click)="toShareByEmail()">
        <span class="text-white"> {{ 'Share by email' | translate | uppercase }} </span>
      </button>
    </div>
    <ng-template #shareBlock>
      <div *ngIf="shareContract === ODF_SIGN_CHECKER.NOT_SIGN"
        class="text-center mt-4 mb-2">
        <button type="button" class="mr-1 btn btn-primary" (click)="goToOdfContract()">
          <span class="text-white"> {{ 'See contract details' | translate | uppercase }} </span>
        </button>
      </div>
    </ng-template>
    <div *ngIf="recreateContract" class="text-center mt-2 mb-2">
      <button type="button" class="mr-1 btn btn-primary" (click)="toSignUp()">
        <span class="text-white"> {{ 'Recreate contract' | translate | uppercase }} </span>
      </button>
    </div>
  </div>
</main>