import { Pipe, PipeTransform } from '@angular/core';
import { getInvoiceStatus } from 'app/helpers/invoice.helper';

@Pipe({
  name: 'invoiceStatus'
})
export class InvoiceStatusPipe implements PipeTransform {

  transform(invoice: any, isOdf: boolean): string {
    return getInvoiceStatus(invoice, isOdf);
  }
}
