import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/internal/operators/pluck';

import { catchError } from 'rxjs/operators';

import { IServerResponse, IDelete } from '../../models/common.models'
import { IUser, IAdminUser, IUserService, UserTypes } from '../../models/user.model'
import { AuthService } from '../auth/auth.service';

@Injectable()

export class UserService {
  user: IUser;

  private url = '/user';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
  }

  getMyUser = (): Observable<any> => (
    this.http.get<any>(`${this.url}/me`).pipe(catchError(this.handleGetMeUser))
  );

  editMyUser = (editedUser: object): Observable<IServerResponse<IUser>> => (
    this.http.patch<IServerResponse<IUser>>(`${this.url}/me`, editedUser).pipe(catchError(this.handleError))
  );

  updateUserLang = (data: object): Observable<IServerResponse<IUser>> => (
    this.http.patch<IServerResponse<IUser>>(`${this.url}/me`, data).pipe(catchError(this.handleError))
  );

  deleteUser = (id: string): Observable<IServerResponse<IDelete>> => (
    this.http.delete<IServerResponse<IDelete>>(`${this.url}/${id}`).pipe(catchError(this.handleError))
  );

  resendInvitation = (id: string): Observable<any> => (
    this.http.patch<any>(`${this.url}/${id}/resend`, {}).pipe(catchError(this.handleError))
  );

  getAllUsers = (limit?: number, offset?: number, filter?: any): Observable<IServerResponse<IUser[]>> => (
    this.http.get<IServerResponse<IUser[]>>(this.url, { params: { limit, offset, ...filter } }).pipe(catchError(this.handleError))
  );

  createServiceUser = (data: IUserService): Observable<IServerResponse<IUser>> => (
    this.http.post<IServerResponse<IUser>>(`${this.url}/service`, data)
  );

  addDomain = (id: number): Observable<any> => (
    this.http.patch<any>(`${this.url}/${id}/domains/add`, {}).pipe(catchError(this.handleError))
  );

  removeDomain = (userId: number, domainId: number): Observable<any> => (
    this.http.patch<any>(`${this.url}/${userId}/domains/${domainId}`, {}).pipe(catchError(this.handleError))
  );

  isAdmin = (): Observable<IServerResponse<IAdminUser>> => (
    this.http.post<IServerResponse<IAdminUser>>(`${this.url}/isAdmin`, []).pipe(catchError(this.handleError))
  );

  changePassword = (password: string, activationHash: string): Observable<any> => (
    this.http.patch(`${environment.apiUrl}/auth/change-password`, { password, activationHash })
      .pipe(catchError(this.handleError))
  );

  changeUserType = (id: string, type: UserTypes) => (
    this.http.patch(`${this.url}/${id}/type`, { type })
  );

  getUserDetails = (id: string): Observable<any> => (
    this.http.get(`${this.url}/${id}`).pipe(pluck('data'))
  );

  updateUser = (user: IUser): Observable<IUser> => (
    this.http.put<IUser>(`${this.url}/${user._id}`, { ...user }).pipe(pluck('data'))
  );

  updateOdfUserInfo = (data: object): Observable<any> => (
    this.http.patch<any>(`${this.url}/me/odf/`, data)
  );

  checkEmail = (email: string): Promise<any> => (
    this.http.post(`/emails`, { email }).toPromise()
      .catch(this.handleError)
  );

  private handleError(error: any): Promise<any> {
    console.error('An error occurred:', error);
    return Promise.reject(error.message || error);
  }

  private handleGetMeUser(err) {
    if (err.status === 401) {
      this.authService.logout();
    }
    return err
  }
}
