export interface IWindowData {
  title?: string;
  text: string;
  text1?: string;
  textCenter1?: string;
  button1: string;
  button2?: string;
}

export const contractDetail: IWindowData = {
  title: 'Contract reviewal is almost done!',
  text: 'Complete the process by filling out the form (KYC) we will send to you within the next 24 hours. After this step eSynFinance will review and grant you access to eSyn OnDemand Finance.',
  textCenter1: 'Thank you for your patience!',
  button1: 'See contract details',
  button2: 'Recreate contract'
}

export const gideConfirmation: IWindowData = {
  title: 'Your ODF is now active!',
  text: 'Your coming invoices will now be financed automatically with eSyn ODF and paid at once. If you wish to finance the invoices manually you can change the settings in the ODF settings page',
  button1: 'Finance method'
}

export const isShowOdfTutorial: IWindowData = {
  title: 'eSyn Finance tutorial',
  text: '',
  textCenter1: 'Do you want see tutorial about new ODF features?',
  button1: 'Next',
  button2: 'Cancel'
}

export const isShowUploadTutorial: IWindowData = {
  title: 'eSynCloud Tutorial',
  text: '',
  textCenter1: 'Check out how eSynCloud works',
  button1: 'Next',
  button2: 'Cancel'
}

export enum ODF_SIGN_CHECKER {
  CAN_SIGN = 'CAN_SIGN',
  NOT_SIGN = 'NOT_SIGN',
  ONE_SIDE_SIGN = 'ONE_SIDE_SIGN'
}
