import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ICustomer } from 'app/models/invoice.model';

@Component({
  selector: 'app-customer-info-alert',
  templateUrl: './customer-info-alert.component.html',
  styleUrls: ['./customer-info-alert.component.scss']
})
export class CustomerInfoAlertComponent {

  @Input() customer: ICustomer;
  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  close() {
    this.activeModal.close();
  }
}
