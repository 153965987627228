<section>
  <div class="odf-header" *ngIf="isOdfPopUp; else esynCloudLogo">
    <img src="../../../../assets/img/logos/esynpay.png" width="50%" alt="eSyn Finance eSynPay">
  </div>
  <ng-template #esynCloudLogo>
    <div class="odf-header">
      <img src="../../../../assets/img/icons/eSynLogo.png" width="50%" alt="eSyn Finance eSynPay">
    </div>
  </ng-template>
  <div class="odf-body">
    <div class="odf-body-text mb-3 text-center">
      <h4 *ngIf="windowData.title"> {{ windowData.title | translate }} </h4>
    </div>
    <div class="odf-body-text">
      <p>
        {{ windowData.text | translate }}
      </p>
      <p *ngIf="windowData.text1">
        {{ windowData.text1 | translate }}
      </p>
      <p *ngIf="windowData.textCenter1" class="text-center">
        {{ windowData.textCenter1 | translate }}
      </p>
    </div>
    <div class="odf-body-button">
      <div *ngIf="windowData.button1" class="btn-1 btn btn-primary" (click)="dispatch(windowData.button1)">
        <span class="text-white"> {{ windowData.button1 | translate | uppercase }} </span>
      </div>
      <div *ngIf="windowData.button2" class="btn-1 btn btn-primary" (click)="dispatch(windowData.button2)">
        <span class="text-white"> {{ windowData.button2 | translate | uppercase }} </span>
      </div>
    </div>
  </div>
</section>