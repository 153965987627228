export enum InvoiceListUrl {
  adminUrl = '/admin/invoices/list',
  odfUrl = '/eSynPay/invoices/list',
  userUrl = '/invoices/list',
}

export enum InvoiceDetails {
  admin = '/admin/invoices/detail/',
  user = '/invoices/detail/',
  userOdf = '/eSynPay/invoices/detail/',
}

export const allowedNewAmountStatuses = ['PDF Opened', 'Denied', 'Canceled'];

export const noAllowedNewAmountStatuses = ['PDF Forward'];
