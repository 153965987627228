import { Pipe, PipeTransform } from "@angular/core";

enum FailedCheck {
  'Sender is in EcoFact' = 1,
  'Registered domain, but sender is different',
  'No sender and no domain was registered',
}

@Pipe({ name: 'failedCheck' })
export class FailedCheckPipe implements PipeTransform {
  transform(caseValue: number): string {
    return FailedCheck[caseValue];
  }
}
