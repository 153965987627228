export const environment = {
    url: 'http://al2.invoice.ecofact.ai/',
    metaUrl: 'http://al2.invoice.ecofact.ai/',
    production: false,
    staging: true,
    apiUrl: 'http://eco-fact-api-al2.eba-sambv7bk.eu-central-1.elasticbeanstalk.com/v1',
    auth0ClientId: 'SBVdfuJNPB0rWNarJMfwB8ijmxIug1eK',
    auth0Domain: 'ecofact-stage.eu.auth0.com',
    callbackURL: 'http://al2.invoice.ecofact.ai/callback',
    apiAudience: 'https://ecofact-stage.eu.auth0.com/api/v2/',
  };
  