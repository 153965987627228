import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService, IDecodeResponse } from '../auth/auth.service';
import { getFormSpec, getContactFormSpec, getSupplierDetailsFormSpec } from './sign-up.form.spec';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../services/alerts.service';
import { IAddressInformation, IAccountDetails } from '../../models/common.models';
import { FindsyService } from '../services/findsy.service';
import { ICompanyPublicInfo } from 'app/models/findsy.model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

const regExWithoutSpecChar = /[^0-9A-Za-z]/g;
@Component({
  selector: 'app-login',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  public form: FormGroup;
  public supplierDetailsForm: FormGroup;
  public contactForm: FormGroup;
  public signUpErr: string;
  public addresses: string[] = [];
  public addressesError = false;
  public emailCheckErr = '';
  public openDomainChecked = false;
  public termsChecked = false;
  public supplier: ICompanyPublicInfo;
  public addressControl = new FormControl('', [Validators.email]);
  public step = 1;
  public activePanel = '';
  public stepsState = {
    first: true,
    second: false,
    third: false,
  }
  public addressInformation: IAddressInformation;
  public accountDetails: IAccountDetails;
  public loading = false;
  public openDomain = '';
  decodeInfo: IDecodeResponse;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private alertService: AlertsService,
    private findsyService: FindsyService,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    // localStorage.clear();

    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }

    this.formInit();
    const queryParams = this.activatedRoute.snapshot.queryParams;
    if (queryParams.hash) {
      this.authService.getInviteInfo(queryParams.hash).subscribe((response: IDecodeResponse) => {
        this.decodeInfo = response;
        this.router.navigateByUrl('/sign-up');
        if (this.decodeInfo.email) {
          this.form.get('email').setValue(this.decodeInfo.email);
        }
      })
    }
  }

  private formInit() {
    this.form = this.fb.group(getFormSpec(), { validator: this.checkPasswords })
    this.supplierDetailsForm = this.fb.group(getSupplierDetailsFormSpec())
    this.contactForm = this.fb.group(getContactFormSpec())
  }

  private checkPasswords(group: FormGroup) {
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPass').value;

    return pass === confirmPass ? null : { notSame: true }
  }


  public next(): void {
    this.authService.checkExistingUser(this.email).subscribe((isExist: boolean) => {
      if (!isExist) {
        this.loading = true;
          this.findsyService.getCompanyPublicInfo(this.form.value.org_id.replace(regExWithoutSpecChar, ''), this.email).subscribe(res => {
            if (res.data) {
              this.openDomain = this.form.get('email').value.split('@')[1];
              const { local, bic, iban, city, country, street, zip } = res.data;
              this.addresses = [this.form.get('email').value];
              this.addressInformation = { city, country, street, zip };
              this.accountDetails = { local, bic, iban };
              this.supplier = res.data;
              this.activePanel = 'panel-2';
              this.step += 1;
              this.loading = false;
            }
          },
          error => {
            this.alertService.error('Dear user, we could not verify you as a supplier');
            this.loading = false;
          })
      } else {
        this.alertService.error('User already exist')
      }
    })
  }

  public handleCheck(checked: boolean): void {
    this.openDomainChecked = checked;
  }

  public handleTermsCheck(checked: boolean): void {
    this.termsChecked = checked;
  }

  public addAddress(): void {
    if (this.addresses.includes(this.addressControl.value)) {
      this.addressesError = true;
      return;
    }

    if (!this.addressControl.value) {
      return;
    }
    this.addresses.push(this.addressControl.value)
    this.addressControl.setValue('')
  }

  public removeAddress(i: number): void {
    this.addresses.splice(i, 1);
  }

  public signUp(): void {
    this.signUpErr = null;

    const signUpData = {
      ...this.form.value,
      org_id: this.form.value.org_id.replace(regExWithoutSpecChar, ''),
      contactInfo: this.contactForm.value,
      senders_mails: this.addresses,
      domain: this.openDomainChecked ? this.openDomain : '',
      email: this.email,
    }

    if (!this.supplier.isOwned) {
      signUpData.companyInfo = {
        ...this.accountDetails,
        ...this.addressInformation,
      }
    }

    this.authService.signUp(signUpData).subscribe((user) => {
      this.alertService.success('You are almost done! Please verify your account by clicking the link sent to your email address. After verification you can log into your account!')
        .then(() => {
          this.step = 1;
          this.form.reset()
        }); // TODO(A.Haidar)
      // temporary solution
      // const modalRef = this.modalService.open(VerifyComponent, {
      //   windowClass: 'modal-window',
      //   backdrop : 'static',
      //   keyboard : false
      // });
      // modalRef.componentInstance.user = user;
      // modalRef.result.then(res => {
      // this.router.navigate(['sign-in'])
      // })
    },
      ({ error }) => this.signUpErr = error.data
    )
  }

  goToLastStep(event) {
    this.addressInformation = event.addressInformation;
    this.accountDetails = event.accountDetails;
    this.signUp();
    // temporary
    // this.companyService.putCompany({
    //   id: this.supplier.id,
    //   accountDetails: this.accountDetails,
    //   addressInformation: this.addressInformation
    // }).then(() => {
    //   // move to four step
    //   this.signUp();
    //   // temporary solution
    //   // this.activePanel = 'panel-4';
    //   // this.stepsState.third = true;
    // }).catch((err) => {
    //   swal({
    //     title: this.translate.instant('Error'),
    //     text: err.message,
    //     type: 'error',
    //     showCancelButton: false,
    //     confirmButtonText: this.translate.instant('Ok'),
    //     confirmButtonClass: 'btn btn-raised btn-primary',
    //     buttonsStyling: true
    //   });
    // })
  }

  goToFirstStep(event) {
    event.preventDefault();
    this.signUpErr = '';
    this.step = 1;
    this.activePanel = 'panel-1'
  }

  get contactEmail() { return this.contactForm.get('email') };
  get contactNumber() { return this.contactForm.get('number') };
  get email() { return this.form.get('email').value };

  get checkContactForm() {
    if (
      (this.contactEmail.value || this.contactNumber.value) &&
      Object.values(this.stepsState).every(Boolean)
      ) {
      return true;
    } else {
      return false;
    }
  }
}
