import { Pipe, PipeTransform } from '@angular/core';

import { ODF_PAYMENT } from 'app/shared/constants/common.constants';
import { oneDay } from 'app/shared/invoices-list/invoices-list.constant';
import { checkSellable } from 'heplers/isSellable';

@Pipe({
  name: 'newDate'
})
export class NewDatePipe implements PipeTransform {

  transform(value: any): string {
    if (checkSellable(value)) {
      return ODF_PAYMENT;
    } else if (value.keeros_invoice && value.keeros_invoice.created_at) {
      let time = new Date(value.keeros_invoice.created_at).getTime()
      let threeDayAfterCreate = (time + (3 * oneDay))
      return new Date(threeDayAfterCreate).toString()
    }
    return '';
  }

}
