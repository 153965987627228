import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';

import { OdfBasePopUpComponent } from 'app/shared/odf-pop-ups/odf-base-pop-up/odf-base-pop-up.component';
import { AlertsService } from 'app/shared/services/alerts.service';
import { UserService } from 'app/shared/services/user.service';
import { gideConfirmation, isShowOdfTutorial } from '../constants/pop-up.constant';

export enum TutorialSteps {
  NEW_DUE_DATE = 'NEW_DUE_DATE',
  NEW_AMOUNT = 'NEW_AMOUNT',
  INVOICE_STATUS = 'INVOICE_STATUS',
  GO_TO_DASHBOARD = 'GO_TO_DASHBOARD',
  CHART = 'CHART',
  SOLD_INVOICES = 'SOLD_INVOICES',
  INVOICES_22_HOUR = 'INVOICES_22_HOUR',
  GO_TO_ODF_SETTINGS = 'GO_TO_ODF_SETTINGS',
  AUTOMATE = 'AUTOMATE',
  USERS = 'USERS',
}

@Injectable({
  providedIn: 'root',
})
export class TutorialService {
  readonly arrayTutorialSteps = Object.values(TutorialSteps);
  readonly isShowOdfTutorial = isShowOdfTutorial;

  isOnTutorial = new BehaviorSubject<boolean>(false);
  currentStep = TutorialSteps[this.arrayTutorialSteps[0]];
  currentIndexStep = 0;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private userService: UserService,
    private alertService: AlertsService
  ) {}

  nextStep = () => {
    if (this.isGoToDashboardStep) {
      this.router.navigateByUrl('/eSynPay/dashboard');
    }
    if (this.isGoToOdfSettings) {
      this.router.navigateByUrl('/eSynPay/settings');
    }
    if (this.isLastStep) {
      this.reset();
      this.userService.updateOdfUserInfo({ isTutorialShown: true }).subscribe();
      return;
    }
    this.currentIndexStep = this.currentIndexStep + 1;
    this.updateStep();
  };

  backStep = () => {
    if (this.isFirstStep) {
      this.reset();
      return;
    }
    this.currentIndexStep = this.currentIndexStep - 1;
    this.updateStep();
  };

  reset = () => {
    this.currentIndexStep = 0;
    this.currentStep = TutorialSteps[this.arrayTutorialSteps[0]];
    this.setTytorialState(false);
  };

  updateStep = () => {
    this.currentStep = TutorialSteps[this.arrayTutorialSteps[this.currentIndexStep]];
  };

  showTutorial = () => {
    if (!this.isOpenTutorial()) {
      const popUpOdflRef = this.modalService.open(OdfBasePopUpComponent, { windowClass: 'myCustomSmallModal modalWindowByCenter' });
      popUpOdflRef.componentInstance.windowData = this.isShowOdfTutorial;
      popUpOdflRef.result.then((value: string) => {
        if (value === this.isShowOdfTutorial.button1) {
          this.router.navigateByUrl('/eSynPay/invoices/list');
          this.setTytorialState(true);
        } else {
          this.userService.updateOdfUserInfo({ isTutorialShown: true }).subscribe();
          const modalRef = this.modalService.open(OdfBasePopUpComponent, { windowClass: 'myCustomSmallModal modalWindowByCenter' });
          modalRef.componentInstance.windowData = gideConfirmation;
          modalRef.result.then(() => this.router.navigateByUrl('/eSynPay/settings'));
        }
      });
    }
  };

  public isOpenTutorial = (): boolean => this.isOnTutorial.getValue();

  public setTytorialState = (value: boolean) => this.isOnTutorial.next(value);

  get isFirstStep() {
    if (this.isFirstStepInDashboard) {
      return true;
    }
    return this.currentIndexStep === 0;
  }
  get isLastStep() {
    return this.currentIndexStep === this.arrayTutorialSteps.length - 1;
  }
  get isGoToDashboardStep() {
    return this.currentStep === TutorialSteps.GO_TO_DASHBOARD;
  }
  get isGoToOdfSettings() {
    return this.currentStep === TutorialSteps.GO_TO_ODF_SETTINGS;
  }
  get isFirstStepInDashboard() {
    return this.currentStep === TutorialSteps.AUTOMATE;
  }
}
