<div class="wrapper">
    <div
      class="app-sidebar"
      data-active-color="white"
      data-background-color="white"
      *ngIf="!tutorialService?.isOpenTutorial() && !uploadTutorialService?.stateUploadTutorial"
    >
        <app-sidebar [isAdmin]="isAdmin"></app-sidebar>
        <div class="sidebar-background"></div>
    </div>
    <div
      class="main-panel"
      [ngClass]="{'main-panel-tutorial': tutorialService?.isOpenTutorial() || uploadTutorialService?.stateUploadTutorial}"
    >
      <div>
        <app-navbar [isAdminMenu]="isAdmin"></app-navbar>

        <div class="main-content">
          <div class="content-wrapper">
            <div class="container-fluid">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
      <app-footer></app-footer>
  </div>
</div>

