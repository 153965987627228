<div class="modal-body py-0">
  <div class="title-text-box row">
    <div class="col-offset-2"></div>
    <div class="col-8 title-text text-white">{{ 'Verify and login' | translate }}</div>
    <div class="col-offset-2"></div>
  </div>
  <div class="col-md-12 mt-4">
    <p class="modal-sub-title">{{ 'A verification notice has been sent to ' + user.email | translate}}</p>
    <p class="modal-sub-title">{{ 'Activate your account by following the link!' | translate}}</p>
  </div>
  <div class="col-12 actions-wrap">
    <div>{{ 'Resend verification' | translate }}</div>
    <div class="coming-soon-feature">
      <button class="btn btn-primary send-btn btn-round text-white" [disabled]="intSec > 0" (click)="resendVerification()">{{ 'SMS'  | translate }}</button>
      <p class="coming-soon-label">coming soon</p>
    </div>
    <button class="btn btn-primary send-btn btn-round text-white" [disabled]="intSec > 0" (click)="resendVerification()">{{ 'Email'  | translate }}</button>
  </div>
  <div class="col-12">
    <p class="modal-sub-title" *ngIf="intSec > 0">{{ 'Please wait for ' + intSec + 's before trying again' | translate }}</p>
  </div>
  <div class="title-text-box row">
    <div class="col-offset-2"></div>
    <button class="col-8 mb-0 title-text btn-close text-white btn btn-primary" (click)="activeModal.close()">{{ 'Close' | translate }}</button>
    <div class="col-offset-2"></div>
  </div>
</div>
