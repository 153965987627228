import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';

import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';

const URL = environment.apiUrl + '/invoice';

@Component({
  selector: 'app-upload-invoice',
  templateUrl: './upload-invoice.component.html',
  styleUrls: ['./upload-invoice.component.scss']
})
export class UploadInvoiceComponent implements OnInit {

  readonly idToken = 'Bearer ' + localStorage.getItem('id_token');
  public displayUploadAll = false;
  public hasBaseDropZoneOver = false;
  public uploader: FileUploader;

  @Output()
  emiterFile: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public translate: TranslateService
  ) {
    this.uploaderInit();
  }

  ngOnInit() {
    this.uploaderOnAfterAddingFile();
    this.emiterFile.emit(this.uploader)
  }

  uploaderInit() {
    this.uploader = new FileUploader({
      url: URL,
      isHTML5: true,
      authToken: this.idToken,
      disableMultipart: false,
      itemAlias: 'file',
      allowedFileType: ['pdf'],
      maxFileSize: 5 * 1024 * 1024
    });
  }

  uploaderOnAfterAddingFile() {
    this.uploader.onAfterAddingFile = (item => {
      item.withCredentials = false;
    })
    this.emiterFile.emit(this.uploader)
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
}
