import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-automated-option',
  templateUrl: './automated-option.component.html',
  styleUrls: ['./automated-option.component.scss']
})
export class AutomatedOptionComponent implements OnInit {

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  close(): void {
    this.activeModal.close();
  }

}
