export enum ContentTableTypes {
  TEXT = 'text',
  INFO = 'info',
  ICON = 'icon',
  ICONreverse = 'icon reverse',
  IconStateSupplier = 'icon supplier',
  FileNamePipe = 'FileNamepipe',
  AddressPipe = 'addressPipe',
  FailedCasePipe = 'failed case pipe',
  FailedCheckPipe = 'failed check pipe',
  EmailPipe = 'senderEmails pipe',
  DATE = 'date',
  InvoiceProgres = 'invoiceProgres',
  PeppolProgres = 'peppolProgres',
  CheckboxChange = 'checkbox change',
  CheckboxAutomated = 'checkbox automated',
  Button = 'button',
  multiButton = 'multi button',
  multiButtonUserFailed = 'button for user failed',
  stageOfFailedCase = 'method for failed case',
}

export const DomainsDetails = [
  {
    title: 'Invoice ID',
    value: 'invoice_id',
    otherValue: 'location',
    type: ContentTableTypes.FileNamePipe,
    sortBy: 'invoice_id',
  },
  {
    title: 'Sender',
    value: 'sender.email',
    type: ContentTableTypes.TEXT,
    sortBy: 'company',
  },
  {
    title: 'Customer',
    value: 'customer.name',
    type: ContentTableTypes.TEXT,
    sortBy: 'customer',
  },
  {
    title: 'Receive Date',
    value: 'created_at',
    type: ContentTableTypes.DATE,
  },
  {
    title: 'Details',
    value: '_id',
    type: ContentTableTypes.INFO,
  },
];

export const EmailList = [
  {
    title: 'Subject',
    value: 'subject',
    type: ContentTableTypes.TEXT,
    sortBy: 'subject',
  },
  {
    title: 'Date of the receiving',
    value: 'receive_time',
    type: ContentTableTypes.DATE,
    sortBy: 'data',
  },
  {
    title: 'From',
    value: 'from',
    type: ContentTableTypes.TEXT,
    sortBy: 'from',
  },
  {
    title: 'To',
    value: 'to',
    type: ContentTableTypes.TEXT,
    sortBy: 'to',
  },
  {
    title: 'Info',
    value: '_id',
    type: ContentTableTypes.INFO,
  },
];

export const FailedSingUpUser = [
  {
    title: 'Company ID',
    value: 'org_id',
    type: ContentTableTypes.TEXT,
    sortBy: 'org_id',
  },
  {
    title: 'User email',
    value: 'email',
    type: ContentTableTypes.TEXT,
    sortBy: 'user_id',
  },
  {
    title: 'Failed at',
    value: 'caseType',
    type: ContentTableTypes.stageOfFailedCase,
    sortBy: 'failed_at',
  },
  {
    title: 'Sign up action',
    value: 'caseType',
    type: ContentTableTypes.TEXT,
    sortBy: 'action',
  },
  {
    title: '',
    value: 'isResolve',
    type: ContentTableTypes.multiButtonUserFailed,
  },
  {
    title: 'Details',
    value: '_id',
    type: ContentTableTypes.INFO,
  },
];

export const Userslist = [
  {
    title: 'Email',
    value: 'auth0.email',
    otherValue: 'email',
    type: ContentTableTypes.TEXT,
    sortBy: 'email',
  },
  {
    title: 'Supplier',
    value: 'company.name',
    defaultValue: 'N/A',
    type: ContentTableTypes.TEXT,
    sortBy: 'company',
  },
  {
    title: 'Type',
    value: 'type',
    type: ContentTableTypes.TEXT,
    sortBy: 'type',
  },
  {
    title: 'In Domain',
    value: 'domain',
    type: ContentTableTypes.ICON,
  },
  {
    title: 'Is verified',
    value: 'auth0.is_email_verified',
    type: ContentTableTypes.ICON,
  },
  {
    title: 'Actions',
    value: 'type',
    type: ContentTableTypes.multiButton,
  },
  {
    title: 'Details',
    value: '_id',
    type: ContentTableTypes.INFO,
  },
];

export const AllowedDomain = [
  {
    title: 'Domain Name',
    value: 'name',
    type: ContentTableTypes.TEXT,
    sortBy: 'name',
  },
  {
    title: 'Associated Supplier',
    value: 'company.name',
    type: ContentTableTypes.TEXT,
    sortBy: 'company',
  },
  {
    title: 'Description',
    value: 'description',
    type: ContentTableTypes.TEXT,
  },
  {
    title: 'Created',
    value: 'created_at',
    type: ContentTableTypes.DATE,
    sortBy: 'created_at',
  },
  {
    title: 'Actions',
    value: 'name',
    type: ContentTableTypes.Button,
    class: 'btn btn-raised btn-danger m-0',
    buttonText: 'Remove',
  },
];

export const OpenChannelDomainsList = [
  {
    title: 'Domain Name',
    value: 'name',
    type: ContentTableTypes.TEXT,
    sortBy: 'name',
  },
  {
    title: 'Number Of Senders',
    value: 'users_count',
    defaultValue: 0,
    type: ContentTableTypes.TEXT,
    sortBy: 'users_count',
  },
  {
    title: 'Number Of PDF Forwarded',
    value: 'invoices_count',
    defaultValue: 0,
    type: ContentTableTypes.TEXT,
    sortBy: 'invoices_count',
  },
  {
    title: '',
    value: '',
    type: ContentTableTypes.Button,
    class: 'copy-email-button',
    buttonText: 'Copy Invitation Link',
  },
  {
    title: 'Details',
    value: '_id',
    type: ContentTableTypes.INFO,
  },
];

export const ReplyChannelDomainsList = [
  {
    title: 'Domain Name',
    value: 'name',
    type: ContentTableTypes.TEXT,
    sortBy: 'name',
  },
  {
    title: 'Senders',
    value: 'users',
    type: ContentTableTypes.EmailPipe,
  },
  {
    title: 'Number Of PDF Received',
    value: 'receiveCount',
    type: ContentTableTypes.TEXT,
    defaultValue: 0,
  },
];

export const FailedCasesList = [
  {
    title: 'Message ID',
    value: 'messageId',
    type: ContentTableTypes.TEXT,
    sortBy: 'messageId',
  },
  {
    title: 'Associated Supplier',
    value: 'company.name',
    type: ContentTableTypes.TEXT,
    sortBy: 'company',
  },
  {
    title: 'Associated Customer',
    value: 'customer.name',
    type: ContentTableTypes.TEXT,
    sortBy: 'customer',
  },
  {
    title: 'From',
    value: 'from',
    type: ContentTableTypes.TEXT,
    sortBy: 'from',
  },
  {
    title: 'To',
    value: 'to',
    type: ContentTableTypes.TEXT,
    sortBy: 'to',
  },
  {
    title: 'Case',
    value: 'case',
    type: ContentTableTypes.FailedCasePipe,
    sortBy: 'case',
  },
  {
    title: 'Check Result',
    value: 'domainCheck',
    type: ContentTableTypes.FailedCheckPipe,
    sortBy: 'domainCheck',
  },
  {
    title: 'Processed',
    value: 'processed',
    type: ContentTableTypes.ICON,
  },
  {
    title: 'Info',
    value: '_id',
    type: ContentTableTypes.INFO,
  },
];

export const SuppliersList = [
  {
    title: 'Company',
    value: 'name',
    type: ContentTableTypes.TEXT,
    sortBy: 'name',
  },
  {
    title: 'Org. ID',
    value: 'org_id',
    type: ContentTableTypes.TEXT,
    sortBy: 'org_id',
  },
  {
    title: 'Tax Number',
    value: 'tax_number',
    type: ContentTableTypes.TEXT,
    sortBy: 'tax_number',
  },
  {
    title: 'Phone',
    value: 'phone',
    type: ContentTableTypes.TEXT,
    sortBy: 'phone',
  },
  {
    title: 'Address',
    value: 'address',
    type: ContentTableTypes.AddressPipe,
    sortBy: 'address',
  },
  {
    title: 'State',
    value: 'phone',
    type: ContentTableTypes.IconStateSupplier,
  },
  {
    title: 'Info',
    value: '_id',
    type: ContentTableTypes.INFO,
  },
];

export const InvalidSuppliersList = [
  {
    title: '',
    value: '_id',
    type: ContentTableTypes.CheckboxChange,
  },
  {
    title: 'Name',
    value: 'name',
    type: ContentTableTypes.TEXT,
  },
  {
    title: 'Org ID',
    value: 'org_id',
    type: ContentTableTypes.TEXT,
  },
  {
    title: 'FTP(peppol)',
    value: 'automated',
    checkParamString: 'automated_ocr_forward',
    type: ContentTableTypes.CheckboxAutomated,
  },
  {
    title: 'FTP(peppol)',
    value: 'automated_ocr_forward',
    checkParamString: 'automated',
    type: ContentTableTypes.CheckboxAutomated,
  },
  {
    title: 'Status',
    value: 'status',
    type: ContentTableTypes.TEXT,
  },
  {
    title: 'Info',
    value: '_id',
    type: ContentTableTypes.INFO,
  },
];

export const Customers = [
  {
    title: 'Customer',
    value: 'name',
    type: ContentTableTypes.TEXT,
    sortBy: 'name',
  },
  {
    title: 'Org. ID',
    value: 'org_id',
    type: ContentTableTypes.TEXT,
    sortBy: 'org_id',
  },
  {
    title: 'Tax Number',
    value: 'tax_number',
    type: ContentTableTypes.TEXT,
    sortBy: 'tax_number',
  },
  {
    title: 'Peppol ID',
    value: 'peppol_id',
    type: ContentTableTypes.TEXT,
    sortBy: 'peppol_id',
  },
  {
    title: 'CUSTOMER INVOICE EMAIL ADDRESS',
    value: 'phone',
    type: ContentTableTypes.Button,
    buttonText: 'Copy Email',
    class: 'copy-email-button',
  },
  {
    title: ' ',
    value: '_id',
    type: ContentTableTypes.INFO,
  },
];

export const PeppolList = [
  {
    title: 'Invoice no.',
    value: 'invoice_id',
    type: ContentTableTypes.TEXT,
    sortBy: 'invoiceID',
  },
  {
    title: 'Supplier',
    value: 'company.name',
    type: ContentTableTypes.TEXT,
    sortBy: 'company',
  },
  {
    title: 'Customer',
    value: 'customer.name',
    type: ContentTableTypes.TEXT,
    sortBy: 'customer',
  },
  {
    title: 'Invoice Status',
    value: 'status',
    type: ContentTableTypes.PeppolProgres,
    sortBy: 'status',
  },
  {
    title: 'Info',
    value: '_id',
    type: ContentTableTypes.INFO,
  },
];

export const InvoiceList = [
  {
    title: 'Invoice ID',
    value: 'invoice_id',
    otherValue: 'location',
    type: ContentTableTypes.FileNamePipe,
    sortBy: 'invoice_id',
  },
  {
    title: 'Invoice Type',
    value: 'type',
    type: ContentTableTypes.TEXT,
    sortBy: 'type',
  },
  {
    title: 'Supplier',
    value: 'company.name',
    type: ContentTableTypes.TEXT,
    sortBy: 'company',
  },
  {
    title: 'Customer',
    value: 'customer.name',
    type: ContentTableTypes.TEXT,
    sortBy: 'customer',
  },
  {
    title: 'Stuck State',
    value: 'customer.name',
    type: ContentTableTypes.ICONreverse,
    sortBy: 'stuck',
  },
  {
    title: 'Due Date',
    value: 'due_date',
    type: ContentTableTypes.DATE,
    sortBy: 'due_date',
  },
  {
    title: 'Amount',
    value: 'amount',
    type: ContentTableTypes.TEXT,
    sortBy: 'amount',
  },
  {
    title: 'Invoice status',
    value: 'status',
    type: ContentTableTypes.InvoiceProgres,
    sortBy: 'status',
  },
  {
    title: 'Info',
    value: 'invoice._id',
    type: ContentTableTypes.INFO,
  },
];

export const SuppliersCustomer = [
  {
    title: 'Full Name',
    value: 'name',
    type: ContentTableTypes.TEXT,
    sortBy: 'name',
  },
  {
    title: 'Org. ID',
    value: 'org_id',
    type: ContentTableTypes.TEXT,
    sortBy: 'org_id',
  },
  {
    title: 'Supplier status',
    value: 'status',
    type: ContentTableTypes.TEXT,
    sortBy: 'status',
  },
  {
    title: 'State',
    value: 'status',
    type: ContentTableTypes.IconStateSupplier,
  },
  {
    title: 'Info',
    value: 'invoice._id',
    type: ContentTableTypes.INFO,
  },
];
