import { Injectable } from '@angular/core';
import { IUser } from 'app/models/user.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataTransferUserService {

  private userData = new BehaviorSubject<Partial<IUser>>({})

  setMeUser(user: IUser) {
    this.userData.next(user);
  }

  getMeUser() {
    return this.userData.getValue()
  }

  constructor() { }
}
