export enum NEW_USER_ERROR {
  USER_IS_MEMBER_OF_COMPANY = 'UserIsMemberOfCompany',
  USER_ALREADY_HAS_COMPANY = 'UserAlreadyHasCompany',
  USER_NOT_BELONG_COMPANY = 'UserNotBelongCompany',
  USER_ALREADY_EXIST = 'UserAlreadyExist',
};

export enum USER_ERROR {
  NO_USER = 'NoUser',
  USER_NOT_HAVE_LINKED_CUSTOMER = 'UserNotHaveLinkedCustomer',
}

export enum COMPANY_ERROR {
  COMPANY_NOT_FOUND = 'CompanyNotFound',
  COMPANY_ALREADY_EXIST = 'CompanyAlreadyExist',
  COMPANY_BY_ORG_ID_ALREADY_EXIST = 'CompanyByOrgIdAlreadyExist',
  COMPANY_NOT_ODF = 'CompanyNotODF',
  COMPANY_CAN_NOT_SET_ACTIVE = 'CanNotSetActiveStatusWithoutAutomatedForwardFtpPeppolOrOcrChecked',
  COMPANY_CAN_NOT_CHANGE_STATUS = 'CanNotChangeStatusFromActiveToOnboardingOrTraining'
}

export enum CUSTOMER_ERROR {
  CUSTOMER_NOT_FOUND = 'CustomerNotFound',
  CUSTOMER_ALREADY_EXIST = 'CustomerAlreadyExist',
  NOT_FIND_ASSOCIATED_CUSTOMERS = 'NotFindAssociatedCustomers'
}

export enum INVOICE_ERROR {
  CAN_NOT_CHANGE_INVOICE_STATUS = 'CanNotSetInvoiceStatus',
}

export enum ODF_ERROR {
  ONE_FLOW_CONTRACT_NOT_FOUND = 'OneFlowContractNotFound'
}
