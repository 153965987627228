<div class="row">
  <div class="col-md-12 col-lg-12" id="my-drop-zone">
    <div
      ng2FileDrop
      [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
      (fileOver)="fileOverBase($event)"
      [uploader]="uploader"
      (click)="inputUploader.click()"
      class="py-5 mb-3 text-center font-medium-5 text-uppercase grey my-drop-zone">
      {{ 'Upload' | translate }} PDF (MAX 5MB.)
    </div>
    <input
      #inputUploader
      type="file"
      ng2FileSelect
      [uploader]="uploader"
      class="hidden-input"
    />
  </div>
</div>