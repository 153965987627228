<div class="lang" *ngIf="step === 1">
  <div class="lang-header">
    <div class="lang-header-circle">
      <img src="../../../assets/img/svg/globe.svg" alt="Language icon">
    </div>
    </div>
    <h4 class="lang-text text-center"> {{ 'Choose your language' | translate }} </h4>
    <div class="lang-body">
      <div class="dropdown-item lang" (click)="chooseLanguage(language.en)">
        <span class="ml-1">English</span>
        <div class="body-flag">
          <img src="./assets/img/flags/us.png" alt="English" class="langimg">
        </div>
      </div>
      <div class="dropdown-item lang" (click)="chooseLanguage(language.se)">
        <span class="ml-1">Svenska</span>
        <div class="body-flag">
          <img src="./assets/img/flags/se.png" alt="Swedish" class="langimg">
        </div>
      </div>
      </div>
</div>
<!-- <div class="section" *ngIf="step === 2">
  <h4 class="text-center mt-4"> {{ 'New function' | translate }} </h4>
  <p>
    {{ 'eSynCloud will add new and beneficial functionalities over time. This button will appear once you have a feature
    ready' | translate }}
  </p>
  <div class="text-center mt-3">
    <span class="btn btn-primary next" (click)="next()">
      {{ 'Next' | translate }}
    </span>
  </div>
</div> -->