import { IAccountDetails, IAddressInformation, IFTP } from './common.models';
import { IGetInvoices } from './invoice.model';
import { IUser } from './user.model';

export enum ODF_STATUS {
  NO_ODF = 'NO_ODF',
  WAITING = 'WAITING',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
}
export interface ICompany {
  accounts: IAccountDetails;
  address: IAddressInformation;
  automated?: boolean;
  automated_ocr_forward?: boolean;
  automated_pdf_forward?: boolean;
  children?: any[];
  confirmed?: boolean | string;
  created_at?: string;
  customers?: string[] | any[];
  days_since_last_invoice?: number;
  ean_code?: string;
  envelope?: boolean;
  lastInvoice?: string | IGetInvoices | any;
  name: string;
  org_id: string;
  odf?: IODFCompany;
  phone?: string;
  prio: boolean;
  prioCustomers?: string[];
  prioPercentage?: string;
  prioSuppliers?: string[];
  recently_active?: boolean;
  show_notifications?: boolean;
  status?: string;
  status_change_date?: string;
  tax_number: string;
  training_start_date?: string;
  updated_at: string;
  users?: string[] | IUser[];
  add_req?: string;
  bulk?: string;
  parent?: string;
  bulkPercentage?: string;
  created_by?: string;
  forward_email?: string;
  ftp?: IFTP;
  invoice_email?: string;
  min_req?: string;
  keerosDetails: IKeerosDetails;
  total?: string;
  totalPercentage?: string;
  is_odf_feature_enabled: boolean;
  __v?: number;
  _id: string;
  id?: string;
  odf_email: string;
  send_odf_notification: boolean;
}
export interface IPutCompany extends ICompany {
  flow_box: any[];
  id: string;
}

export interface IKeerosDetails {
  interest: number;
  issuerID: string;
  oauth: IOAuthKeerosCompany;
  service: number;
  _id: string;
}

export interface IOAuthKeerosCompany {
  client_id: string;
  client_secret: string;
  grant_type: string;
  scope: string;
}

export interface IODFCompany {
  isAutoSale: boolean;
  signatory_name: string;
  status: ODF_STATUS;
  activation_date: string;
  lastOdfInviteSent: string;
}

export interface IOAuthKeerosCompany {
  client_id: string;
  client_secret: string;
  grant_type: string;
  scope: string;
}
