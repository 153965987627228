import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { DataTransferUserService } from 'app/shared/services/data-transfer-user.service';
import { UserService } from 'app/shared/services/user.service';
import { Observable } from 'rxjs';
import { catchError, map, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService,
    private dataTransferUserService: DataTransferUserService,
  ) { }

  // We get user and save his for use this data into callback.component.ts and navbar.component.ts
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {

    return this.userService.getMyUser().pipe(
      pluck('data'),
      map(user => {
        this.dataTransferUserService.setMeUser(user);

        // If user is customer
        if (user?.customer_company) {
          if (state.url.includes('/analytics')) {
            return true;
          } else {
            this.router.navigate(['/analytics/dashboard'])
          }
        } else { // If user is company user
          if (state.url.includes('/analytics')) {
            this.router.navigate(['/invoices/list']);
          } else {
            return true;
          }
        }
      }),
      catchError(async () => true)
    )
  }
}
