import { ICompany } from './company.model'
import { OdfStatuses } from './odf.model';

// User interface
export interface IUser {
  addresses: string[];
  auth0?: IAuth0;
  company?: any; // TODO add types ICompany | string
  created_at: string;
  customer_company?: any;
  id?: string;
  isAdmin: boolean;
  isTutorialShown: boolean;
  isFirstLogin: boolean;
  language: Language;
  name?: string;
  email?: string;
  phone: string;
  sendConfirmationByEmail: boolean;
  sendNotificationByEmail: boolean;
  sender_emails?: Array<any>;
  sendingConfirmationByEmail?: boolean;
  sendingDisabled: boolean;
  sendingNotificationByEmail?: boolean;
  type: string;
  updated_at: string;
  __v?: number;
  _id: string;
  odfInformation?: IOdfInformation;
}

export interface IOdfInformation {
  status: OdfStatuses;
  isOdfAdmin: boolean;
  isTutorialShown: boolean;
  isActivatedPopupShown: boolean
}

export interface IAddNewUser {
  isNew: boolean;
  user: IUser;
}

export interface IUserService {
  email: string;
}

export enum UserTypes {
  user = 'User',
  dataUser = 'Data-User',
  service = 'Service',
  openChannel = 'Open Channel',
  reply = 'Reply',
}

export interface IAuth0 {
  email: string;
  id: string;
}

export interface IAdminUser {
  isAdmin: boolean;
  _id: string;
  odfInformation?: IOdfInformation;
  company?: ICompany;
  id?: string;
}

export interface IOdfInformation {
  status: OdfStatuses;
  isOdfAdmin: boolean;
  isTutorialShown: boolean;
}

export interface IAddNewUser {
  isNew: boolean;
  user: IUser;
}

export interface IUserService {
  email: string;
}

export interface IAddNewUser {
  isNew: boolean;
  user: IUser;
}

export interface IUserService {
  email: string;
}

// Toggle user interface
export interface IToggleUser {
  _id: string;
  sendNotificationByEmail: boolean;
  sendConfirmationByEmail: boolean;
}

export type Language = 'en' | 'se';
