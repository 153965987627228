import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IServerResponse } from '../../models/common.models'
import { IGetInvoice } from '../../models/json.model'

@Injectable()

export class JsonService {

  private url = '/bis-engine';

  constructor(private http: HttpClient) { }

  getInvoice = (id: string): Observable<IServerResponse<IGetInvoice>> => (
    this.http.get<IServerResponse<IGetInvoice>>(`${this.url}/${id}`)
      .pipe(catchError(this.handleError))
  );

  private handleError(error: any): Promise<any> {
    console.error('An error occurred:', error);
    return Promise.reject(error.message || error);
  }
}
