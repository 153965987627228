<nav class="navbar navbar-expand-lg navbar-light bg-faded">
  <div class="container-fluid">
    <div class="navbar-header">
      <button
        type="button"
        class="navbar-toggle d-lg-none float-left"
        data-toggle="collapse">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
      </button>
      <div class="navbar-button">
        <a
          class="btn btn-outline-danger"
          href="javascript:;"
          (click)="UploadInvoice()"
          *ngIf="uploadInvoiceButton"
        >{{ 'Upload Invoice' | translate }}</a>
        <a
          [routerLink]="['/eSynPay/invoices/list']"
          class="animated-button1 btn btn-outline-danger"
          *ngIf="isUserAndCompanyCanBeOdf && isShowBtnFeatureService.getIsShowButton()"
          >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          {{ 'New feature' | translate }}
        </a>
        <a (click)="recreateContract()" class="animated-button1 btn btn-outline-danger text-white" *ngIf="showButtonActivateESynPay">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          {{ 'Activate eSynPay' | translate }}
        </a>
      </div>
    </div>
    <div class="navbar-container mr-1">
      <div class="navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item mr-2">
            <div class="nav-link position-relative" id="navbar-fullscreen" appToggleFullscreen>
              <i class="font-medium-3 blue-grey darken-4 ft-maximize"></i>
            </div>
          </li>
          <li class="nav-item language-box" ngbDropdown placement="bottom-right">
            <a class="nav-link position-relative" id="dropdownLang" ngbDropdownToggle>
              <i class="ft-flag font-medium-3 blue-grey darken-4"></i>
            </a>
            <div
              ngbDropdownMenu
              aria-labelledby="dropdownLang"
              class="dropdownLang"
            >
              <a
                class="dropdown-item py-1 lang"
                href="javascript:;"
                (click)="ChangeLanguage(language.en)"
                [ngClass]="{'dropdown-item-selected': currentLang === language.en}"
              >
                <img
                  src="./assets/img/flags/us.png"
                  alt="English"
                  class="langimg"
                  width="22"
                >
                <span>English</span>
              </a>
              <a
                class="dropdown-item py-1 lang"
                href="javascript:;"
                (click)="ChangeLanguage(language.se)"
                [ngClass]="{'dropdown-item-selected': currentLang === language.se}"
              >
                <img
                  src="./assets/img/flags/se.png"
                  alt="Swedish"
                  class="langimg"
                  width="22"
                >
                <span>Svenska</span>
              </a>
            </div>
          </li>
          <li class="nav-item" ngbDropdown placement="bottom-right">
            <a class="nav-link position-relative" id="dropdownBasic3" ngbDropdownToggle>
              <i class="ft-user font-medium-3 blue-grey darken-4"></i>
            </a>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
              <ng-container *ngIf="isUserOfCompany">
                <a class="dropdown-item py-1" href="javascript:;" (click)="navigateToSettings()">
                  <i class="ft-settings mr-2"></i>
                  <span>{{ 'Settings' | translate }}</span>
                </a>
                <div class="dropdown-divider"></div>
              </ng-container>
              <a class="dropdown-item" href="javascript:;" (click)="authService.logout()">
                <img
                  src="assets/img/logout.svg"
                  alt="logout icon"
                  class="img-logout mr-2"
                >
                <span>{{ 'Logout' | translate }}</span>
              </a>
              <div *ngIf="!isAdminMenu && isAdmin">
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" routerLink="/admin/dashboard">
                  <i class="ft-lock mr-2"></i>
                  <span>{{ 'Admin Page' | translate }}</span>
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
