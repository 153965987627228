<main class="tutorial">
  <div
    class="tutorial__content"
    [ngClass]="{'tutorial__content-index': isShowTutorial}"
  >
    <ng-content select="[content]"></ng-content>
  </div>
  <ng-container *ngIf="isShowTutorial">
    <div class="tutorial__background"></div>
    <div class="tutorial__content tutorial__info">
      <ng-content select="[info]"></ng-content>
      <div class="tutorial__buttons">
        <button
          class="btn btn-secondary mb-0 mt-2"
          (click)="skipTutorial()"
          *ngIf="!tutorialService?.isLastStep"
        >
          <span>{{ 'Skip' | translate }}</span>
        </button>
        <div>
          <button
            class="btn btn-warning mr-2 mb-0 mt-2"
            (click)="backStep()"
            *ngIf="!tutorialService?.isFirstStep"
          >
            <span class="text-white">{{ 'Back' | translate }}</span>
          </button>

          <button
            class="btn btn-primary mb-0 mt-2"
            (click)="nextStep(tutorialService?.isLastStep)"
          >
            <span class="text-white">{{ (tutorialService?.isLastStep ? 'Finish' : 'Next') | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</main>
