import {Component, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { AuthService } from '../auth/auth.service';
import { forkJoin } from 'rxjs';
import { concatMap, mergeMap, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  public form: FormGroup;
  public checkResults: Boolean;
  public resetSuccessful: Boolean;
  public emailControl = new FormControl('', [Validators.email, Validators.required]);

  constructor(
    private userService: UserService,
    private authService: AuthService
  ) {}

  ngOnInit() {
  }

  public checkEmail(): void {
    this.userService.checkEmail(this.emailControl.value).then(res => {
      this.checkResults = res.data.available;
      return this.authService.passwordReset(this.emailControl.value);
    }).then(res => {
      this.resetSuccessful = true;
    });
  }
}
