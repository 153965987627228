import {FormBuilder, Validators} from "@angular/forms";

export function getFormSpec() {
  return {
    'org_id': ['', [Validators.required]],
    'email': ['', [Validators.required, Validators.email]],
    'password': ['', [
                      Validators.required,
                      Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!/%*?&])[A-Za-z\d@$!%*/#?&]{8,}$/),
                      Validators.minLength(8)
                    ]],
    'confirmPass': ['', Validators.required],
  }
}

export function getSupplierDetailsFormSpec() {
  return {
    'street': [''],
    'city': [''],
    'zip': [''],
    'country': [''],
    'local': [''],
    'iban': [''],
    'swift': [''],
  }
}

export function getContactFormSpec() {
  return {
    email: ['', Validators.email],
    number: ['', Validators.minLength(8)]
  }
}

export const formErrors = {
  org_id: {
    required: 'Org ID field is empty'
  },
  email: {
    required: 'Email field should not be empty',
    email: 'Email field is not in valid format'
  },
  password: {
    required: 'Password should not be empty',
    pattern: 'Should have lower case upper case letters, numbers and special character(@$!%*/#?&)',
    minlength: 'Password contains less than 8 characters'
  },
  confirmPass: {
    required: 'Should not be empty'
  }
};
