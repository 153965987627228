<main>
  <div class="accordion-forms__title">
    <img src="assets/img/icons/home.svg">
    <p>{{ 'Address info' | translate }}</p>
  </div>
  <form [formGroup]="addressInfoForm" *ngIf="addressInfoForm">
    <label class="accordion__input">
      <p>{{ 'Street' | translate }} <span class="text-danger">*</span></p>
      <input
        type="text"
        formControlName="street"
      >
    </label>
    <label class="accordion__input">
      <p>{{ 'ZIP' | translate }} <span class="text-danger">*</span></p>
      <input
        type="text"
        formControlName="zip"
      >
    </label>
    <label class="accordion__input">
      <p>{{ 'City' | translate }} <span class="text-danger">*</span></p>
      <input
        type="text"
        formControlName="city"
      >
    </label>
    <label class="accordion__input">
      <p>{{ 'Country' | translate }} <span class="text-danger">*</span></p>
      <input
        type="text"
        formControlName="country"
      >
    </label>
  </form>
  <div class="accordion-forms__title">
    <img src="assets/img/icons/hash.svg">
    <p>{{ 'Accounts details' | translate }}</p>
  </div>
  <form [formGroup]="accountDetailsForm" *ngIf="accountDetailsForm">
    <label class="accordion__input">
      <p>{{ 'Local' | translate }}</p>
      <input
        type="text"
        formControlName="local"
      >
    </label>
    <label class="accordion__input">
      <p>{{ 'IBAN' | translate }}</p>
      <input
        type="text"
        formControlName="iban"
      >
    </label>
    <label class="accordion__input">
      <p>{{ 'BIC/SWIFT' | translate }}</p>
      <input
        type="text"
        formControlName="bicSwift"
      >
    </label>
    <div class="d-flex align-items-center justify-content-between">
        <div
          class="custom-upload form-group"
          (click)="fileInput.click()"
          *ngIf="isNewUser"
        >
          <div class="d-flex align-items-center">
            <i class="icon-info text-primary info-link"
              [ngbPopover]="pdfInfo"
              placement="top"
              triggers="mouseenter:mouseleave"
            ></i>
            <p class="upload-pdf">{{ 'Upload PDF' | translate }}</p>
            <input
              #fileInput
              type="file"
              id="file"
              (change)="handleFileInput($event.target.files)"
            >
          </div>
        </div>
        <!-- temporary, rename to 'Next' -->
      <button
        class="btn next-button btn-primary btn-round"
        (click)="onNext()"
        [disabled]="(!accountDetailsForm.valid && isNewUser) || (!addressInfoForm.valid && isNewUser)"
      >{{ 'Sign up' | translate }}
        <!--  -->
      </button>
    </div>
  </form>
  <ng-template #pdfInfo>
    <div class="info-popover">{{ 'Fetch my information from my invoice, upload a copy' | translate }}</div>
  </ng-template>
</main>

<div *ngIf="loading" class="loading-pdf">
  <img src="assets/img/oval.svg" />
  <span>loading...</span>
</div>
