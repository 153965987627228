export interface IKeerosInvoice {
  OCR: string;
  amount: number;
  created_at: string;
  currency: IKeerosCurrency;
  eRegistered: string;
  keerosStatuses: Array<IKeerosStatuses>;
  interest: number;
  invoiceDate: string;
  invoiceExpiryDate: string;
  invoiceID: string;
  invoiceNumber: string;
  KeerosStatuses?: IKeerosStatuses;
  originalValue: number;
  registrationDate: string;
  remainingCapital: number;
  remainingToPay: number;
  updated_at: string;
  _id: string;
}

export interface IKeerosCurrency {
  name: string;
  shortName: string;
}

export interface IKeerosStatuses {
  factoringStatus: KEEROS_STATUSES;
  factoringStatusID: number;
  statusDate: string;
  _id: string;
}

export enum KEEROS_STATUSES {
  REGISTERED = 'Registrerad',
  REJECTED = 'Avvisad',
  APPROVED = 'Godkänd',
  WAITING_FOR_DELIVERY_APPROVAL = 'Avvaktar LG',
  PAID = 'Betald',
  PARTLY_PAID = 'Delbetald',
  REMINDER_1 = 'Påminnelse 1',
  REMINDER_2 = 'Påminnelse 2',
  DEBT_COLLECTION = 'Inkassso',
  SETTLEMENT = 'Avräkning',
  REVERSED = 'Återförd',
  DONE = 'Klar',
  CANCELED = 'Makulerad',
  CHARGED = 'Återdebiterad',
}

export enum KEEROS_FINAL_STATUSES {
  REJECTED =  KEEROS_STATUSES.REJECTED,
  PAID =  KEEROS_STATUSES.PAID,
  CANCELED = KEEROS_STATUSES.CANCELED,
};

export const ProgressKeerosInvoicesStatuses = [
  { value: KEEROS_STATUSES.REGISTERED, title: 'Finance Requested', percent: 75, type: 'success' },
  { value: KEEROS_STATUSES.REJECTED, title: KEEROS_STATUSES.REJECTED, percent: 100, type: 'danger' },
  { value: KEEROS_STATUSES.APPROVED, title: 'Finance Accepted', percent: 100, type: 'success' },
  { value: KEEROS_STATUSES.WAITING_FOR_DELIVERY_APPROVAL, title: KEEROS_STATUSES.WAITING_FOR_DELIVERY_APPROVAL, percent: 100, type: 'warning' },
  { value: KEEROS_STATUSES.PARTLY_PAID, title: KEEROS_STATUSES.PARTLY_PAID, percent: 100, type: 'warning' },
  { value: KEEROS_STATUSES.REMINDER_1, title: KEEROS_STATUSES.REMINDER_1, percent: 100, type: 'warning' },
  { value: KEEROS_STATUSES.REMINDER_2, title: KEEROS_STATUSES.REMINDER_2, percent: 100, type: 'warning' },
  { value: KEEROS_STATUSES.DEBT_COLLECTION, title: KEEROS_STATUSES.DEBT_COLLECTION, percent: 100, type: 'warning' },
  { value: KEEROS_STATUSES.PAID, title: KEEROS_STATUSES.PAID, percent: 100, type: 'success' },
  { value: KEEROS_STATUSES.CANCELED, title: KEEROS_STATUSES.CANCELED, percent: 100, type: 'danger' },
  { value: KEEROS_STATUSES.CHARGED, title: KEEROS_STATUSES.CHARGED, percent: 100, type: 'success' },
  { value: KEEROS_STATUSES.SETTLEMENT, title: 'Finance paid', percent: 100, type: 'success' },
  { value: KEEROS_STATUSES.DONE, title: 'Completed', percent: 100, type: 'success' },
];

export const KeerosDefaultStatus = { value: 'Processing', title: 'Processing', percent: 25, type: 'warning' };
