import { Pipe, PipeTransform } from '@angular/core';
import { TypeOfProgressBar } from 'app/shared/progress/invoices.progress.component';

@Pipe({
  name: 'statusSource'
})
export class StatusSourcePipe implements PipeTransform {

  transform(invoice: any): TypeOfProgressBar {
    return invoice.keeros_invoice ? TypeOfProgressBar.ProgressKeerosInvoicesStatuses : TypeOfProgressBar.KeerosInvoicesStatuses
  }

}
