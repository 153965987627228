import { Input, Output, EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() total: number;
  @Input() limit: number;
  @Input() page: number;
  @Input() title: string;
  @Input() disabled: boolean;
  @Input() loading: boolean;

  @Output() eventPage = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }

  public pageChange(page): void {
   this.eventPage.emit(page)
  }

}
