import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';
import { SupportInformation } from '../constants/support.constants';

export const supportMessage = `Please contact support email: ${SupportInformation.email}, number: ${SupportInformation.phone}`

@Injectable()
export class AlertsService {
  constructor(private translate: TranslateService) { }

  public warning = (text: string, title = 'Warning', cancelButton = 'No', confirmButton= 'Yes'): Promise<any> => (
    swal({
      title: this.translate.instant(title),
      text: text ? this.translate.instant(text) : '',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: this.translate.instant(cancelButton),
      confirmButtonText: this.translate.instant(confirmButton),
      confirmButtonClass: 'btn btn-raised btn-primary',
      buttonsStyling: true
    })
  )

  public success = (text: string): Promise<any> => (
    swal({
      title: this.translate.instant('Success'),
      text: this.translate.instant(text),
      type: 'success',
      showCancelButton: false,
      confirmButtonText: this.translate.instant('Continue'),
      confirmButtonClass: 'btn btn-raised btn-primary',
      buttonsStyling: true
    })
  )

  public error = (error: string, buttonText: string = 'Ok', payloadText: string = ''): Promise<any> => (
    swal({
      title: this.translate.instant('Error'),
      text: `${this.translate.instant(error) + payloadText}. ${supportMessage}`,
      type: 'error',
      showCancelButton: false,
      confirmButtonText: this.translate.instant(buttonText),
      confirmButtonClass: 'btn btn-raised btn-primary',
      buttonsStyling: true
    })
  )

  public info = (text: string): Promise<any> => (
    swal({
      type: 'info',
      text:  this.translate.instant(text),
    })
  )

  public tutorial = (text: string, title: string, cancelButton = 'No', confirmButton = 'Yes'): Promise<any> => (
    swal({
      title: this.translate.instant(title),
      text: this.translate.instant(text),
      showCancelButton: true,
      cancelButtonText: this.translate.instant(cancelButton),
      confirmButtonText: this.translate.instant(confirmButton),
      confirmButtonClass: 'btn btn-raised btn-primary',
      buttonsStyling: true
    })
  )

  public choosePayment = (text: string, title: string, buttonText1: string, buttonText2: string, buttonText3: string): Promise<any> => (
    swal({
      type: 'success',
      title: this.translate.instant(title),
      text: text,
      allowOutsideClick: false,
      showConfirmButton: true,
      showCancelButton: true,
      showCloseButton: false,
      confirmButtonText: buttonText2,
      cancelButtonText: buttonText1,
      customClass: 'custom-alert'
    })
  )

  public oneFlow = (type: string): Promise<any> => {
    if (type === 'email') {
      return swal({
        title: 'Your contact details',
        confirmButtonText: 'Proceed',
        html:
          '<h4> Who will be signing </h4>' +
          '<input id="swal-input1" class="swal2-input" placeholder="First name" required>' +
          '<input id="swal-input2" class="swal2-input" placeholder="Last name" required>',
        focusConfirm: false,
        preConfirm: () => {
          return [
            document.getElementById('swal-input1'),
            document.getElementById('swal-input2')
          ]
        }
      })
    } else if (type === 'sms' || type === 'swedishBankID') {
      return swal({
        title: 'Your contact details',
        confirmButtonText: 'Proceed',
        html:
          '<h4> Who will be signing </h4>' +
          '<input id="swal-input1" class="swal2-input" placeholder="First name" required>' +
          '<input id="swal-input2" class="swal2-input" placeholder="Last name" required>' +
          '<input id="swal-input3" class="swal2-input" placeholder="Phone number" required>',
        focusConfirm: false,
        preConfirm: () => {
          return [
            document.getElementById('swal-input1'),
            document.getElementById('swal-input2'),
            document.getElementById('swal-input3')
          ]
        }
      })
    }
  }
}
