import { RouteInfo } from './sidebar.metadata';

export const adminRoutes: RouteInfo[] = [
  {
    path: '/admin/dashboard',
    title: 'Dashboard',
    icon: 'icon-home',
    submenu: [],
    class: '',
  },
  {
    path: '',
    title: 'Invoices',
    icon: 'icon-docs',
    class: 'has-sub',
    submenu:
      [
        {
          path: '/admin/invoices/list',
          title: 'Invoice List',
          icon: '',
          submenu: [],
          class: '',
        },
        {
          path: '/admin/invoices/upload',
          title: 'Upload Invoice',
          icon: '',
          submenu: [],
          class: '',
        }
      ]
  },
  {
    path: '/admin/peppol/list',
    title: 'Peppol',
    icon: 'icon-doc',
    submenu: [],
    class: '',
  },
  {
    path: '/admin/customers',
    title: 'Customers',
    icon: 'icon-users',
    submenu: [],
    class: '',
  },
  {
    path: '',
    title: 'Suppliers',
    icon: 'icon-users',
    class: 'has-sub',
    submenu:
      [
        {
          path: '/admin/companies/list',
          title: 'Suppliers List',
          icon: '',
          submenu: [],
          class: '',
        },
        {
          path: '/admin/companies/invalid',
          title: 'Inactive',
          icon: '',
          submenu: [],
          class: '',
        },
      ]
  },
  {
    path: '/admin/failed-cases',
    title: 'Failed Import Cases',
    icon: 'icon-close',
    submenu: [],
    class: '',
  },
  {
    path: '/admin/domains',
    title: 'Domains',
    icon: 'icon-check',
    class: 'has-sub',
    submenu:
      [
        {
          path: '/admin/domains/allowed',
          title: 'Allowed',
          icon: '',
          submenu: [],
          class: '',
        },
        {
          path: '/admin/domains/open-chanel',
          title: 'Open Channel',
          icon: '',
          submenu: [],
          class: '',
        },
        {
          path: '/admin/domains/reply',
          title: 'Reply Channel',
          icon: '',
          submenu: [],
          class: '',
        },
      ]
  },
  {
    path: '/admin/users',
    title: 'Users',
    icon: 'icon-user',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/admin/users/list',
        title: 'Users list',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/admin/users/failed',
        title: 'Failed signup',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
    ]
  },
  {
    path: '/admin/emails',
    title: 'Emails',
    icon: 'icon-user',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: []
  }
];
