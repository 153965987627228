import { Routes } from '@angular/router';

// Route for admin layout with sidebar, navbar and footer
export const ADMIN_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../admin/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'invoices',
    loadChildren: () => import('../../admin/invoices/invoices.module').then(m => m.InvoicesModule)
  },
  {
    path: 'invoices/list',
    loadChildren: () => import('../../shared/invoices-list/invoices-list.module').then(m => m.InvoicesListModule)
  },
  {
    path: 'peppol',
    loadChildren: () => import('../../admin/peppol/peppol.module').then(m => m.PeppolModule)
  },
  {
    path: 'customers',
    loadChildren: () => import('../../shared/customer/customers.module').then(m => m.CustomersModule)
  },
  {
    path: 'companies',
    loadChildren: () => import('../../admin/companies/companies.module').then(m => m.CompaniesModule)
  },
  {
    path: 'users',
    loadChildren: () => import('../users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'failed-cases',
    loadChildren: () => import('../../admin/failed-cases/failed-cases.module').then(m => m.FailedCasesModule)
  },
  {
    path: 'domains',
    loadChildren: () => import('../../admin/domains/domains.module').then(m => m.DomainsModule)
  },
  {
    path: 'emails',
    loadChildren: () => import('../../shared/email/email.module').then(m => m.EmailModule)
  }
];
