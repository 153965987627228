<main>
  <div class="d-flex justify-content-end mb-1 mt-4">
    <div class="mr-4" (click)="closeModal()">
      <span class="close-modal"></span>
    </div>
  </div>
  <div class="d-flex justify-content-center my-4">
    <img src="../../../../assets/img/logos/esynpay.png" width="50%" alt="eSyn Finance eSynPay">
  </div>
  <h1> {{ 'eSynCloud introduces eSynFinance' | translate }} </h1>

  <p>
    {{ 'You are currently sending your invoices through the platform eSynCloud. eSynCloud transformes your invoices in a
    structured format and validates after your customer requirements.' |
    translate}}
  </p>
  <p>
    {{ 'As a supplier in the eSynCloud network you have the option to have your invoices sent to your customers whithin the
    network to be pre-paid. eSynPay is an add-on module and offers two alternatives to finance invoices.' |
    translate }}
  </p>
  <p>
    {{ 'Auto enables all invoices to be financed as soon as your customer approves the invoices.' | translate }}
  </p>
  <p> {{ 'Spot where you manually choose the invoices to be financed.' | translate }} </p>

  <h4> {{ 'eSynFinance, added benefits' | translate }} </h4>

  <div class="mt-3">
    <div class="group-of-circle">
      <div class="circle">
        <div class="circle-group">
          <h2 class="circle-group-text">
            1
          </h2>
        </div>
        <p> {{ 'Sign the eSynPay agreement from your side' | translate }} </p>
      </div>
      <div class="circle">
        <div class="circle-group">
          <h2 class="circle-group-text">
            2
          </h2>
        </div>
        <p> {{ 'Fill in the ‹Know your customer› form emailed to you' | translate }} </p>
      </div>
      <div class="circle">
        <div class="circle-group">
          <h2 class="circle-group-text">
            3
          </h2>
        </div>
        <p> {{ 'eSynFinance activates your OnDemand Finance in your eSynCloud' | translate }} </p>
      </div>
    </div>
    <div *ngIf="shareContract === ODF_SIGN_CHECKER.CAN_SIGN; else shareBlock">
      <div class="mt-4 mb-2 d-flex justify-content-center" *ngIf="!wasOpen; else focusBlock">
        <a class="mr-1 btn btn-primary" (click)="toSignUp()">
          <span class="text-white"> {{ 'Sign up contract' | translate | uppercase }} </span>
        </a>
        <a class="ml-1 btn btn-primary" (click)="toShareByEmail()">
          <span class="text-white"> {{ 'Share by email' | translate | uppercase }} </span>
        </a>
      </div>
      <ng-template #focusBlock>
        <div class="mt-4 mb-2 d-flex justify-content-center">
          <button type="button" class="mr-1 btn btn-primary" (click)="toSignUp()">
            <span class="text-white"> {{ 'Sign up contract' | translate | uppercase }} </span>
          </button>
          <button type="button" class="ml-1 btn btn-primary" (click)="toShareByEmail()">
            <span class="text-white"> {{ 'Share by email' | translate | uppercase }} </span>
          </button>
        </div>
      </ng-template>
    </div>
    <ng-template #shareBlock>
        <div *ngIf="shareContract === ODF_SIGN_CHECKER.NOT_SIGN" class="text-center mt-4 mb-2">
          <a class="mr-1 btn btn-primary" (click)="goToOdfContract()">
            <span class="text-white"> {{ 'See contract details' | translate | uppercase }} </span>
          </a>
          </div>
          </ng-template>
          </div>
          <div *ngIf="recreateContract" class="text-center mt-2 mb-2">
            <button type="button" class="mr-1 btn btn-primary" (click)="toSignUp()">
              <span class="text-white"> {{ 'Recreate contract' | translate | uppercase }} </span>
            </button>
          </div>
</main>