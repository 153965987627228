import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IServerResponse } from '../../models/common.models'
import { IGetInvoice, ICompanyPeppol } from '../../models/peppol.model'

@Injectable()

export class PeppolService {

  private url = '/peppol/invoice';

  constructor(private http: HttpClient) { }

  getInvoices = (limit: number, offset: number, filter: any): Observable<IServerResponse<IGetInvoice[]>> => (
    this.http.get<IServerResponse<IGetInvoice>>(this.url, { params: { ...filter, limit, offset, getAll: true } })
      .pipe(catchError(this.handleError))
  );

  getPeppolInvoice = (id: string): Observable<IServerResponse<ICompanyPeppol>> => (
    this.http.get(`${this.url}/${id}?generateTempUrls=1`)
      .pipe(catchError(this.handleError))
  );

  getPeppolXML = (xml_url: string): Observable<string> => (
    this.http.get(xml_url, { responseType: 'text' }).pipe(catchError(this.handleError))
  );

  putInvoice = (invoice: any): Observable<any> => (
    this.http.put(`${this.url}/${invoice.id}`, invoice).pipe(catchError(this.handleError))
  );

  deleteInvoice = (id: string): Observable<any> => (
    this.http.delete(`${this.url}/${id}`).pipe(catchError(this.handleError))
  );

  sendInvoiceByFTP = (id: string): Observable<IServerResponse<ICompanyPeppol>> => (
    this.http.post<IServerResponse<ICompanyPeppol>>(`${this.url}/send/${id}`, [])
      .pipe(catchError(this.handleError))
  );

  private handleError(error: any): Promise<any> {
    console.error('An error occurred:', error);
    return Promise.reject(error.message || error);
  }
}
