import { environment } from 'environments/environment';

interface AuthConfig {
  clientID: string;
  domain: string;
  callbackURL: string;
  apiAudience: string;
  connection: string;
}

export const AUTH_CONFIG: AuthConfig = {
  clientID: environment.auth0ClientId,
  domain: environment.auth0Domain,
  callbackURL: environment.callbackURL,
  apiAudience: environment.apiAudience,
  connection: 'Username-Password-Authentication',
};
