import { Component, Input, OnInit } from '@angular/core';
import { adminRoutes } from './sidebar-admin-routes.config';
import { getRoutes } from './sidebar-routes.config';
import { Routes } from '@angular/router';
import { SupportInformation } from '../constants/support.constants';
import { UserService } from '../services/user.service';
import { IUser } from 'app/models/user.model';

declare let $: any;
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  @Input() isAdmin: boolean;
  user: IUser;
  public menuItems: Routes;
  readonly supportInformation = SupportInformation;

  constructor(
    private userService: UserService,
  ) {}

  ngOnInit() {
    $.getScript('./assets/js/app-sidebar.js');
    this.userService.getMyUser().subscribe((data) => {
      if (data) {
        const is_odf_feature_enabled = data.data?.company?.is_odf_feature_enabled;
        const customer_company = data.data?.customer_company;
        this.menuItems = this.isAdmin ? adminRoutes : getRoutes(is_odf_feature_enabled, customer_company);
      }
    })
  }

}
