import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { IServerResponse } from '../../models/common.models';
import { IGetInvoices, InvoiceRequest } from '../../models/invoice.model';

@Injectable()
export class InvoicesService {
  private url = '/invoice';
  private adminUrl = '/admin/invoices';

  constructor(private http: HttpClient) {}

  // Requests for admin role
  getAdminInvoices = (limit?: number, offset?: number, filter?: any): Observable<IServerResponse<IGetInvoices[]>> =>
    this.http
      .get<IServerResponse<IGetInvoices[]>>(this.adminUrl, { params: { limit, offset, ...filter } })
      .pipe(catchError(this.handleError));

  exportAdminInvoices = (filter: any): Observable<any> =>
    this.http.get(`${this.adminUrl}/export/csv`, { params: { ...filter }, responseType: 'blob' }).pipe(
      map((file: Blob) => ({ file })),
      catchError(this.handleError)
    );

  getAdminInvoice = (id: string): Observable<IServerResponse<IGetInvoices>> =>
    this.http.get<IServerResponse<IGetInvoices>>(`${this.adminUrl}/${id}`).pipe(catchError(this.handleError));

  // Requests for client role
  getInvoices = (limit?: number, offset?: number, filter?: any): Observable<IServerResponse<IGetInvoices[]>> =>
    this.http
      .get<IServerResponse<IGetInvoices[]>>('/invoices', { params: { limit, offset, ...filter } })
      .pipe(catchError(this.handleError));

  exportInvoices = (filter: any): Observable<any> =>
    this.http.get(`/invoices/export/csv`, { params: { ...filter }, responseType: 'blob' }).pipe(
      map((file: Blob) => ({ file })),
      catchError(this.handleError)
    );

  getInvoice = (id: string): Observable<IServerResponse<IGetInvoices>> =>
    this.http.get<IServerResponse<IGetInvoices>>(`/invoices/${id}`).pipe(catchError(this.handleError));

  getPdfInvoice = (link: string): Observable<any> => this.http.get(link, { responseType: 'blob' });

  getInvoiceFromOCR = (id: string): Observable<IServerResponse<IGetInvoices>> =>
    this.http.get<IServerResponse<IGetInvoices>>(`${this.url}/ocr/${id}`).pipe(catchError(this.handleError));

  putInvoice = (invoice: InvoiceRequest): Observable<any> => this.http.put<any>(`${this.url}/${invoice.id}`, invoice);

  getDuplicates = (id: string): Observable<IServerResponse<IGetInvoices>> =>
    this.http.get<IServerResponse<IGetInvoices>>(`/invoice/${id}/duplicatesDetails`).pipe(catchError(this.handleError));

  resumeDuplicateProcessing = (id: string): Observable<any> =>
    this.http.post<any>(`${this.url}/${id}/resumeDuplicateProcessing`, {}).pipe(catchError(this.handleError));

  getRawLinks = (invoiceId: string) => this.http.get(`${this.url}/${invoiceId}/raw_links`);

  // Requests for ODF
  getOdfInvoices = (limit?: number, offset?: number, filter?: any): Observable<IServerResponse<IGetInvoices[]>> =>
    this.http
      .get<IServerResponse<IGetInvoices[]>>('/invoices/odf/', { params: { limit, offset, ...filter } })
      .pipe(catchError(this.handleError));

  getOdfInvoice = (id: string): Observable<IServerResponse<IGetInvoices>> =>
    this.http.get<IServerResponse<IGetInvoices>>(`/invoices/odf/${id}`).pipe(catchError(this.handleError));

  private handleError(error: any): Promise<any> {
    console.error('An error occurred:', error);
    return Promise.reject(error.message || error);
  }
}
