import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';


@Injectable()
export class OcrmlService {
  private _url = 'http://ocrml-api.eu-central-1.elasticbeanstalk.com/';

  constructor(private http: HttpClient) {}


  public uploadInvoiceAndReceiveData = (file): Observable<any> => {
    const invoice = new FormData();
    invoice.append('file', file);
    return this.http.post<any>(`${this._url}api/v2/parse_footer/`, invoice);
  };

}
