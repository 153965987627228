import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { UserTypes } from 'app/models/user.model';

@Component({
  selector: 'app-change-user-type',
  templateUrl: './change-user-type.component.html',
  styleUrls: ['./change-user-type.component.scss']
})
export class ChangeUserTypeComponent implements OnInit {
  @Input() type: string;
  @Input() userId: string;

  userTypes = [];

  typeValue: FormControl = new FormControl('');

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.typeValue.setValue(this.type);
    this.setUserTypes(this.type);
  }

  changeType = () => {
    this.activeModal.close({ id: this.userId, type: this.typeValue.value });
    this.setUserTypes(this.typeValue.value);
  }

  setUserTypes = (type) => {
    if (type === UserTypes.user) {
      this.userTypes = Object.values(UserTypes).filter((item: string) => item !== UserTypes.openChannel);
    } else {
      this.userTypes = Object.values(UserTypes);
    }
  }

  trackByForTypes = (_: number, item: string) => item;
}
