import {Component, Input, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IShareContract } from 'app/models/odf.model';

@Component({
  selector: 'app-share-contract',
  templateUrl: './share-contract.component.html',
  styleUrls: ['./share-contract.component.scss']
})
export class ShareContractComponent implements OnInit {
  form: FormGroup;

  @Input() alertMessage: () => void

  constructor(
    private activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email])
    })
  }

  value = (valueFromForm: IShareContract) => {
    this.activeModal.close(valueFromForm)
  };

  toFirstModal = () => {
    this.activeModal.close()
    this.alertMessage()
  }

  closeModal = () => {
    this.activeModal.close()
  }
}
