import { Pipe, PipeTransform } from '@angular/core';
import { checkSellable } from 'heplers/isSellable';

@Pipe({
  name: 'checkSellable'
})
export class CheckSellablePipe implements PipeTransform {

  transform(value: unknown): boolean {
    return checkSellable(value);
  }

}
