<div class="p-2">
  <div class="customer-requirements">
    <div class="min-req">
      <h6 class="customer-requirements__title"> {{ 'Minimum Req.' | translate }} </h6>
      <hr>
      <p *ngIf="customer.min_req; else coomingSoomMinReq" [innerHtml]="customer.min_req"></p>
      <ng-template #coomingSoomMinReq>
        <p>{{ 'Coming soon' | translate }}</p>
      </ng-template>
    </div>
    <div class="add-req">
      <h6 class="customer-requirements__title"> {{ 'Additional Req.' | translate }} </h6>
      <hr>
      <p *ngIf="customer.add_req; else coomingSoomAddReq" [innerHtml]="customer.add_req"></p>
      <ng-template #coomingSoomAddReq>
        <p>{{ 'Coming soon' | translate }}</p>
      </ng-template>
    </div>
  </div>
  <div class="d-flex justify-content-center mt-2">
    <button type="button" class="btn btn-raised btn-danger btn-sm mb-0" (click)="close()">
      {{ 'Close' | translate }}
    </button>
  </div>
</div>