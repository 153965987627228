<main>
  <div class="odf-pop-up-header mt-2">
    <div class="d-flex justify-content-between">
      <div class="ml-4" (click)="toFirstModal()">
        <span class="back-modal"></span>
      </div>
      <h4 class="mb-0 mt-0"> {{ 'Sign up contract' | translate | uppercase }} </h4>
      <div class="mr-4" (click)="closeModal()">
        <span class="close-modal"></span>
      </div>
      </div>
      <hr>
      </div>
  <form [formGroup]="form" (ngSubmit)="value(form.value)">
    <div class="group-bank-inputs">
      <p>
        {{ 'All fields are required' | translate }}.
      </p>

      <input class="form-control" [placeholder]="'First name' | translate" formControlName="firstName">
      <p *ngIf="form.controls.firstName.touched && !form.controls.firstName.value"> {{ 'Field is required' | translate}}
      </p>

      <input class="form-control" [placeholder]="'Last name' | translate" formControlName="lastName">
      <p *ngIf="form.controls.lastName.touched && !form.controls.lastName.value"> {{ 'Field is required' | translate}}
      </p>

      <input class="form-control" [placeholder]="'Phone number' | translate" formControlName="phoneNumber">
      <p *ngIf="form.controls.phoneNumber.touched && !form.controls.phoneNumber.value"> {{ 'Field is required' |
        translate}}
      </p>

      <input class="form-control" [placeholder]="'Email' | translate" formControlName="email" [title]="'eSynPay contact' | translate">
      <p *ngIf="form.controls.email.touched && !form.controls.email.value"> {{ 'Field is required' |
        translate}}
      </p>

      <input class="form-control" [placeholder]="'Personal id for BankID (format: YYYYMMDD-PPPP)' | translate"
        formControlName="personalID">
      <p *ngIf="form.controls.phoneNumber.touched && !form.controls.phoneNumber.value"> {{ 'Field is required' |
        translate}} </p>
    </div>
    <div class="wrap">
      <div class="animated-button1 mt-4 mb-4 d-flex justify-content-center"> <button type="submit" class="button"
          [disabled]="!form.valid"> {{ 'Sign up' | translate }} </button> </div>
    </div>
  </form>
</main>
