import { Component, Input, OnInit } from '@angular/core';
import { OdfInvoiceStatuses } from 'app/client/esyn-finance/constants/keeros.constants';

import { getRemainingTime } from 'app/shared/counter-date/helper'
import { COLORS, LIMIT_HOURS } from './constants'

@Component({
  selector: 'app-counter-date',
  templateUrl: './counter-date.component.html',
  styleUrls: ['./counter-date.component.scss']
})
export class CounterDateComponent implements OnInit {

  readonly COLORS = COLORS;
  readonly LIMIT_HOURS = LIMIT_HOURS
  readonly getRemainingTime = getRemainingTime;
  readonly odfInvoiceStatuses = OdfInvoiceStatuses;

  @Input() date: string | Date;
  @Input() amountTime: number;
  @Input() status: string = '';
  @Input() line: boolean;

  color: string;
  time: string | number;

  constructor(
  ) { }

  ngOnInit(): void {
    this.counterDownDate()
  }

  counterDownDate = () => {
    if (this.date && this.amountTime && this.odfInvoiceStatuses.SEND_TO_KEEROS !== status) {
      const milisecond = new Date(this.date).getTime();
      let newDate = this.getRemainingTime(milisecond, this.amountTime);
      this.time = milisecond + this.amountTime;

        if (newDate > 0) {
          const dateHour = new Date(newDate).getUTCHours();
          newDate = this.getRemainingTime(milisecond, this.amountTime);

          if (newDate > 0 && dateHour > LIMIT_HOURS.GREEN) {
            this.color = COLORS.GREEN;
          } else if (newDate > 0 && dateHour > LIMIT_HOURS.YELLOW) {
            this.color = COLORS.YELLOW;
          } 
        }
    } else {
      this.time = '';
    }
  }
}
