import { Component, OnDestroy} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { TutorialService } from 'app/client/esyn-finance/services/tutorial.service';
import { UploadTutorialService } from 'app/client/invoices/services/upload-tutorial.service';


@Component({
    selector: 'app-full-layout',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.scss']
})

export class FullLayoutComponent implements OnDestroy {
    private url = '/admin'
    public isAdmin: boolean;
    navigationSubscription: Subscription;

    constructor(
        private router: Router,
        public tutorialService: TutorialService,
        public uploadTutorialService: UploadTutorialService
    ) {
        this.navigationSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.isAdmin = this.verifyAdmin(event.url);
            }
        })
    }

    verifyAdmin = (url: string): boolean => url.includes(this.url);

    ngOnDestroy() {
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
    }
}
