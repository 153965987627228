import { Injectable } from '@angular/core';
import { AUTH_CONFIG } from './auth0-variables';
import { Router } from '@angular/router';

import * as auth0 from 'auth0-js';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { AlertsService } from '../services/alerts.service';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

export enum TypeOfAuthErrors {
  NO_COMPANY = 'no-company',
  NOT_VALIDATED = 'not-validated',
  UNKNOWN_LOGIN = 'Unknown or invalid login ticket.',
  UNABLE_CONFIGURE = 'Unable to configure verification page.',
}

export interface IDecodeResponse {
  email: string;
  invoiceBaseInfo: {
    iban: string;
    local: string;
    phone_number: string;
    swift: string;
    supplier_address: {
      city: string;
      zip: string;
      address_line: string;
      street: string;
    }
  }
}

@Injectable()
export class AuthService {

  private auth0: any;

  constructor(
    public router: Router,
    private http: HttpClient,
    private alertService: AlertsService
  ) {
    this.initAuth0Client()
  }

  private initAuth0Client() {
    this.auth0 = new auth0.WebAuth({
      clientID: environment.auth0ClientId,
      domain: environment.auth0Domain,
      redirectUri: AUTH_CONFIG.callbackURL,
      audience: AUTH_CONFIG.apiAudience,
      responseType: 'token id_token',
    });
  }

  public signIn(data) {
    this.auth0.login(
      {
        realm: AUTH_CONFIG.connection, // connection name or HRD domain
        email: data.email,
        password: data.password,
        audience: environment.apiAudience,
        scope: 'openid profile email'
      }, (err) => {
        if (err) {
          this.alertService.error(err.error_description);
        }
      }
    );
    localStorage.setItem('loginEmail', data.email);
  };

  public resendVerification(user_id: string) {
    return this.http.post('/register/resend', { user_id })
  };

  signUp = (data): Observable<any> => (this.http.post(`/createCompanyUser`, data));

  getInviteInfo = (hashedOrgId: string): Observable<IDecodeResponse> => (
    this.http.get<IDecodeResponse>(`/register/getInviteInfo?invite=${encodeURIComponent(hashedOrgId)}`)
      .pipe(pluck('data', 'data'))
  );

  handlerFailedLogin = (email: string) => (
    this.http.post(`/register/handleFailedLogin`, { email })
  );

  public parseHash(hash) {
    this.auth0.parseHash({hash}, (err, authResult) => {
      if (err) {
        localStorage.setItem('errorPage', JSON.stringify(err));
        if (err.errorDescription === TypeOfAuthErrors.NOT_VALIDATED) {
          this.router.navigateByUrl('/verify');
        } else if (err.errorDescription === TypeOfAuthErrors.NO_COMPANY) {
          this.router.navigateByUrl('/not-connected');
        } else if (
          err.errorDescription === TypeOfAuthErrors.UNKNOWN_LOGIN
          || err.errorDescription === TypeOfAuthErrors.UNABLE_CONFIGURE
        ) {
          this.router.navigateByUrl('/error-login');
        } else {
          this.router.navigateByUrl('/error');
        }
      } else {
        const redirectUrl = sessionStorage.getItem('redirectUrlAfterSignIn');
        if (redirectUrl) {
          sessionStorage.removeItem('redirectUrlAfterSignIn');
          if (!redirectUrl.includes('?supportSignUp') && !redirectUrl.includes('?message=')) {
            this.router.navigate([redirectUrl]);
          }
        } else {
          this.router.navigate(['/']);
        }
        this.setSession(authResult);
      }
    });
  }

  public passwordReset(email) {
    return new Promise((resolve, reject) => {
      this.auth0.changePassword({
        connection: AUTH_CONFIG.connection,
        email,
      }, function (err, resp) {
        if (err) {
          reject(err)
        } else {
          resolve(resp)
        }
      });
    });
  }

  public checkExistingUser = (email: string): Observable<boolean> => (
    this.http.post(`/register/checkExisting`, { email }).pipe(pluck('data', 'isExist'))
  );

  private setSession(authResult): void {
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
  }

  public logout(): void {
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('confirmed');
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');

    // Go back to the home route
    this.router.navigate(['/']);
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time

    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));

    return localStorage.getItem('access_token') != undefined
      && localStorage.getItem('id_token') != undefined && new Date().getTime() < expiresAt;
  }
}
