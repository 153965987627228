export enum InvoiceStatuses {
  APPROVED = 'Approved',
  PRECESSING = 'Processing',
  SENDING = 'Sending',
  INVOICE_SENT = 'Invoice Sent',
  IMPORTED = 'Imported',
  CANCELED = 'Canceled',
  PDF_FORWARD = 'PDF Forward',
  PDF_OPENED = 'PDF Opened',
  DUPLICATE = 'Duplicate',
  DELIVERED = 'Delivered',
  DENIED = 'Denied',
  FINISH_PARSING = 'Finish parsing',
};
