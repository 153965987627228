import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { formErrors } from 'app/shared/sign-up/sign-up.form.spec';

@Pipe({
  name: 'getErrorMessage',
  pure: false,
})
export class GetErrorMessagePipe implements PipeTransform {

  transform(form: FormGroup, fieldName: string): string {
      const controlErrors = form.controls[fieldName].errors;

      let errorText = 'error';
      Object.keys(controlErrors).forEach((error) => {
        if (error) {
          return errorText = formErrors[fieldName][error];
        }
      });
      return errorText;
  }

}
