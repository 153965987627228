<div class="pop-up-wrapper">
  <h4 class="mt-2">
    {{
    'Dear user, your added service eSynPay is now active!
    Please choose if you want to manually send invoices to be financed or keep the automated option'
    | translate
    }}
  </h4>
  <div class="button">
    <button class="btn btn-primary mt-2" (click)="close()">
      <div class="text-white"> {{ 'eSynPay settings' | translate }} </div>
    </button>
  </div>
</div>