import { Pipe, PipeTransform } from "@angular/core";

enum FailedCase {
  'No Attachment' = 1,
  'More than one attachment',
  'Invalid attachment',
  'Not associated with customer',
  'No such user',
}

@Pipe({ name: 'failedCase' })
export class FailedCasePipe implements PipeTransform {
  transform(caseValue: number): string {
    return FailedCase[caseValue];
  }
}
