import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'app/shared/auth/auth.service';

@Component({
  selector: 'app-sign-up-header',
  templateUrl: './sign-up-header.component.html',
  styleUrls: ['./sign-up-header.component.scss']
})
export class SignUpHeaderComponent implements OnInit {

  loginForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
    })
  }

  public submit() {
    this.authService.signIn(this.loginForm.value);
    this.loginForm.reset();
  }

  ngOnInit() {}

  get email() { return this.loginForm.get('email') };
  get password() { return this.loginForm.get('password') };
}
