export const connectKeerosErrors = {
  bankClearingNo: 'validations_errors.bankClearingNo',
  bankAccountNo: 'validations_errors.bankAccountNo',
};

// Keeros statuses
export enum OdfInvoiceStatuses {
  UNKNOWN = 'UNKNOWN',
  NOT_ODF = 'NOT_ODF',
  SELLABLE = 'Sellable',
  NOT_SELLABLE = 'Not sellable',
  FAILED_SALE = 'Failed Sale',
  FAILED_SENT_WITHOUT_PAYMENT = 'Failed to sent without payment',
  SEND_TO_KEEROS = 'Send to Keeros',
  SEND_TO_KEEROS_WITHOUT_PAYMENT = 'Keeros without payment',
}
