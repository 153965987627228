import { IInvoiceStatus } from 'app/models/invoices.model';

export const InvoicesStatuses: IInvoiceStatus[] = [
  { value: '', title: 'All', percent: null, type: null },
  { value: 'Approved', title: 'Approved', percent: 100, type: 'success' },
  { value: 'Processing', title: 'Processing', percent: 50, type: 'warning' },
  { value: 'Sending', title: 'Sending', percent: 75, type: 'success' },
  { value: 'Invoice Sent', title: 'Invoice Sent', percent: 75, type: 'success' },
  { value: 'Imported', title: 'Imported', percent: 100, type: 'success' },
  { value: 'Canceled', title: 'Canceled', percent: null, type: null },
  { value: 'PDF Forward', title: 'PDF Forward', percent: null, type: null },
  { value: 'PDF Opened', title: 'PDF Opened', percent: 100, type: 'success' },
  { value: 'Duplicate', title: 'Duplicate', percent: 100, type: 'danger' },
  { value: 'Delivered', title: 'Delivered', percent: 100, type: 'success' },
  { value: 'Denied', title: 'Denied', percent: 100, type: 'danger' },
  { value: 'Finish parsing', title: 'Finish parsing', percent: 30, type: 'success'},
]

export const PeppolStatuses: IInvoiceStatus[] = [
  { value: '', title: 'All', percent: null, type: null },
  { value: 'Automated', title: 'Automated', percent: 75, type: 'success' },
  { value: 'Processing', title: 'Processing', percent: 40, type: 'warning' },
  { value: 'Canceled', title: 'Canceled', percent: null, type: null },
  { value: 'Duplicate', title: 'Duplicate', percent: 100, type: 'danger' },
  { value: 'Sending', title: 'Sending', percent: 80, type: 'success' },
  { value: 'Sent', title: 'Invoice Sent', percent: 80, type: 'success' },
  { value: 'Imported', title: 'Imported', percent: 100, type: 'success' },
  { value: 'Delivered', title: 'Delivered', percent: null, type: null },
  { value: 'Approved', title: 'Approved', percent: 100, type: 'success' },
  { value: 'Denied', title: 'Denied', percent: 100, type: 'danger' },
]

export const InvoicesTypes = [
  { value: '', title: 'All' },
  { value: 'Invoice', title: 'Invoice' },
  { value: 'Credit', title: 'Credit Note' }
];

export const KeerosInvoicesStatuses: IInvoiceStatus[] = [
  { value: 'UNKNOWN', title: 'Unknown', percent: null, type: null },
  { value: 'NOT_ODF', title: 'Not odf', percent: null, type: null },
  { value: 'Sellable', title: 'Sellable', percent: null, type: null },
  { value: 'Not sellable', title: 'Not sellable', percent: 70, type: 'danger' },
  { value: 'Failed Sale', title: 'Failed Sale', percent: 100, type: 'danger' },
  { value: 'Send to Keeros', title: 'Send to Keeros', percent: 70, type: 'success' },
  { value: 'Failed to sent without payment', title: 'Failed', percent: 100, type: 'danger' },
  { value: 'Keeros without payment', title: 'Sent without finance', percent: 0, type: 'danger' },
  { value: 'Paid', title: 'Paid', percent: 100, type: 'success' },
]
