import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HistoryUrlService {

  private history: Array<string> = [];

  constructor(
    private router: Router
    ) {}

    public addNewUrl(): void {
      this.router.events.pipe(
        filter(v => v instanceof NavigationEnd)
      ).subscribe((evt: NavigationEnd) => {
          this.history.push(evt.urlAfterRedirects);
          sessionStorage.setItem('history_url', JSON.stringify(this.history));
      })
    }

    public getLastUrlBeforeError(): string {
      const url = JSON.parse(sessionStorage.getItem('history_url'));
      return url.length > 2 ? url[url.length - 1] : '/';
    }
}
