import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { FailedSignUpCases } from '../../admin/users/failed-sign-up/constants';
import { ContentTableTypes } from '../constants/view-mobile.constant';
import { informationUrl } from 'app/shared/helpers/methods';
import { ICustomer } from 'app/models/invoice.model';
import { CustomerInfoAlertComponent } from '../customer/customers/customer-info-alert/customer-info-alert.component';

enum UserTypes {
  user = 'User',
  dataUser = 'Data-User',
  service = 'Service',
  openChannel = 'Open Channel',
  reply = 'Reply',
}

@Component({
  selector: 'app-mobile-view-for-table',
  templateUrl: './mobile-view-for-table.component.html',
  styleUrls: ['./mobile-view-for-table.component.scss']
})
export class MobileViewForTableComponent implements OnInit {

  readonly contentTableTypes = ContentTableTypes;
  public userTypes = UserTypes;
  public curentlyRoute = this.router.url;

  @Input() pointOflist: any[] = [];
  @Input() data;

  @Output() emitDataOnClickOne = new EventEmitter();
  @Output() emitDataOnClickTwo = new EventEmitter();

  @Output() emitDataForRowSelectMethod = new EventEmitter();
  @Output() emitDataForCheckParamMethod = new EventEmitter();
  @Output() emitDataForCheckDisabledMethod = new EventEmitter();

  constructor(
    private router: Router,
    public modalService: NgbModal,
  ) {
  }

  ngOnInit(): void {
  }

  stageOfFailedCase = (caseType: string) => FailedSignUpCases[caseType];

  emitDataOne(data) {
    this.emitDataOnClickOne.emit(data)
  }

  emitDataTwo(data) {
    this.emitDataOnClickTwo.emit(data)
  }

  infoUrl(id) {
    const url = informationUrl(id, this.router.url);
    this.router.navigate([url])
  }

  rowSelect(id: number, checked: boolean) {
    this.emitDataForRowSelectMethod.emit({ id, checked })
  }

  checkParam(id: number) {
    this.emitDataForCheckParamMethod.emit(id)
  }

  checkDisabled(company: any, checked: boolean, field: string) {
    this.emitDataForCheckDisabledMethod.emit({ company, checked, field })
  }

  modalCustomerInfo(customer: ICustomer) {
    const customerModal = this.modalService.open(CustomerInfoAlertComponent);
    customerModal.componentInstance.customer = customer
  }
}
