import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export class TranslateSettingsService {

  constructor(
    private translate: TranslateService,
  ) { }

  public getLanguage(currentLang: string = ''): string {
    if (currentLang) {
      localStorage.setItem('language', currentLang);
      return currentLang;
    } else {
      const browserLang: string = this.translate.getBrowserLang();
      const language = localStorage.getItem('language') || (browserLang.match(/en|se/) ? browserLang : 'se');
      localStorage.setItem('language', language);
      this.translate.use(language);
      return language;
    }
  }

  public changeLanguage(language: string) {
    localStorage.setItem('language', language);
    this.translate.use(language);
  }

  // For customer dashboard page
  public getTranslateDashboard(): Observable<any> {
    return new Observable(observer => {
      const lang = localStorage.getItem('language');
      let company, numInvoices, defaulS, Inactive, Onboarded, Training, Activated;

      this.translate.getTranslation(lang).subscribe(v => {
        company = v['Company'];
        numInvoices = v['No. of Invoices'];
        defaulS = v['Sorry, there is no data for the selected time period'];
        Inactive = v['Inactive'];
        Onboarded = v['Onboarded'];
        Training = v['ML Training'];
        Activated = v['Activated'];
      },
        err => { },
        () => {
          observer.next({
            company, numInvoices, defaulS, Inactive, Onboarded, Training, Activated
          })
        }
      );
    })
  }
}
