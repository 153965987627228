import { Routes } from '@angular/router';

// Route for content layout with sidebar, navbar and footer
export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../client/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'customers',
    loadChildren: () => import('../../shared/customer/customers.module').then((m) => m.CustomersModule),
  },
  {
    path: 'invoices',
    loadChildren: () => import('../../client/invoices/invoices.module').then((m) => m.InvoicesModule),
  },
  {
    path: 'invoices/list',
    loadChildren: () => import('../../shared/invoices-list/invoices-list.module').then((m) => m.InvoicesListModule),
  },
  {
    path: 'faq',
    loadChildren: () => import('../../client/faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('../../client/settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'analytics',
    loadChildren: () => import('../../customer/analytics.module').then((m) => m.AnalyticsModule),
  },
  {
    path: 'eSynPay',
    loadChildren: () => import('../../client/esyn-finance/esyn-finance.module').then((m) => m.EsynFinanceModule),
  },

  // TODO (a.haidar@quantumobile.com): Add emails tab for client role (simply user).
  // {
  //   path: 'emails',
  //   loadChildren: () => import('../../shared/email/email.module').then(m => m.EmailModule)
  // }
];
