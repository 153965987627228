import {NgModule, Pipe, PipeTransform} from "@angular/core";

@Pipe({ name: 'fileName' })
export class FileNamePipe implements PipeTransform {
  transform(location: string): string {
    if (location?.length) {
      const locationArr = location && location.split('/') || [];
      return locationArr[locationArr.length - 1].slice(0, -4);
    }
    return ''
  }
}

@NgModule({
  declarations: [FileNamePipe],
  exports: [FileNamePipe]
})

export class FileNamePipeModule { }
