import { isExpired } from 'heplers/isSellable';
import { KeerosDefaultStatus } from 'app/client/esyn-finance/models/keeros.model';
import { OdfInvoiceStatuses } from 'app/client/esyn-finance/constants/keeros.constants';
import { InvoiceStatuses } from 'app/customer/constants/invoice-statuses.constants';

export function getFileName(location: string): string {
  if (location?.length) {
    const locationArr = location.split('/');
    return locationArr[locationArr.length - 1].slice(0, -4);
  }
  return ''
}

export function getInvoiceStatus(invoice: any, isOdf: boolean): string {
  if (invoice.status === InvoiceStatuses.DUPLICATE || invoice.status === InvoiceStatuses.PDF_FORWARD) {
    return invoice.status
  }
  if (invoice.keeros_invoice) {
    const { keerosStatuses } = invoice.keeros_invoice;
    return keerosStatuses[keerosStatuses.length - 1].factoringStatus;
  }
  if (
    invoice.odf_status === OdfInvoiceStatuses.SEND_TO_KEEROS_WITHOUT_PAYMENT && isExpired(invoice) ||
    invoice.odf_status === OdfInvoiceStatuses.FAILED_SENT_WITHOUT_PAYMENT && isExpired(invoice)
  ) {
    return invoice.odf_status;
  }
  if (
    isExpired(invoice) ||
    invoice.odf_status === OdfInvoiceStatuses.SEND_TO_KEEROS_WITHOUT_PAYMENT ||
    invoice.odf_status === OdfInvoiceStatuses.SEND_TO_KEEROS
  ) {
    return KeerosDefaultStatus.title;
  }
  return isOdf ? invoice.odf_status : invoice.status;
}
