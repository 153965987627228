import { Pipe, PipeTransform } from '@angular/core';
import { getInvoiceStatus } from 'app/helpers/invoice.helper';
import { allowedNewAmountStatuses, noAllowedNewAmountStatuses } from 'app/shared/constants/invoice.constants';

@Pipe({
  name: 'newAmount'
})
export class NewAmountPipe implements PipeTransform {

  transform(invoice: any): boolean {
    if (invoice.keeros_invoice?.newAmount || noAllowedNewAmountStatuses.includes(invoice.status)) {
      return false;
    } else if (getInvoiceStatus(invoice, true) || isNewAmount(invoice.status)) {
      return true
    }
    return false;
  }
}

function isNewAmount(status: string): boolean {
  if (allowedNewAmountStatuses.includes(status)) {
    return false
  } else {
    return true
  }
}
