import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  readonly loginBeforeActionUrls = [
    'odf/confirmFirstSale'
  ];

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.isAuthenticated()) {
      const { url } = this.router;

      sessionStorage.setItem('redirectUrlAfterSignIn', url);
      this.router.navigate(['sign-up']);
    }
    return true;
  }

}
