import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { gideConfirmation } from 'app/client/esyn-finance/constants/pop-up.constant';
import { TutorialService, TutorialSteps } from 'app/client/esyn-finance/services/tutorial.service';
import { OdfBasePopUpComponent } from '../odf-pop-ups/odf-base-pop-up/odf-base-pop-up.component';

@Component({
  selector: 'app-tutorial-odf',
  templateUrl: './tutorial-odf.component.html',
  styleUrls: ['./tutorial-odf.component.scss'],
})
export class TutorialOdfComponent implements OnInit {
  @Input() currentStep: TutorialSteps = TutorialSteps.AUTOMATE;

  constructor(private router: Router, private modalService: NgbModal, public tutorialService: TutorialService) {}

  ngOnInit(): void {}

  nextStep = (isFinish: boolean) => {
    this.tutorialService.nextStep();
    if (isFinish) {
      const modalRef = this.modalService.open(OdfBasePopUpComponent, { windowClass: 'myCustomSmallModal modalWindowByCenter' });
      modalRef.componentInstance.windowData = gideConfirmation;
      modalRef.result.then(() => this.router.navigateByUrl('/eSynPay/dashboard'));
    }
  };

  backStep = () => {
    this.tutorialService.backStep();
  };

  skipTutorial = () => {
    this.tutorialService.reset();
  };

  get isShowTutorial() {
    return this.tutorialService.currentStep === this.currentStep && this.tutorialService.isOpenTutorial();
  }
}
