import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsShowBtnFeatureService {

  private isShowNewFeatureButton = new BehaviorSubject<boolean>(true)

  constructor() { }

  getIsShowButton(): boolean {
    return this.isShowNewFeatureButton.getValue()
  }

  setStateButton(isShow: boolean): void {
    this.isShowNewFeatureButton.next(isShow);
  }
}
