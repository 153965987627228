import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-upload-tutorial-window',
  templateUrl: './upload-tutorial-window.component.html',
  styleUrls: ['./upload-tutorial-window.component.scss']
})
export class UploadTutorialWindowComponent implements OnInit {

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  toTutorial(): void {
    this.activeModal.close(true);
  }

}
