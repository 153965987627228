import { AfterViewInit, Directive, ElementRef, HostListener, Renderer2, OnInit } from '@angular/core';

export enum ScreenClasses {
  SMALL = 'ft-minimize',
  FULL = 'ft-maximize'
}
@Directive({
  selector: '[appToggleFullscreen]'
})
export class ToggleFullscreenDirective implements AfterViewInit, OnInit {
  public toggleClass: string;
  public iconElement;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
  }

  @HostListener('click')
  onClick() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  ngOnInit() {
    document.addEventListener('fullscreenchange', () => this.verifyFullScreen())
  }


  verifyFullScreen(): void {
    if (document.fullscreenElement) {
      this.toggleClass = ScreenClasses.SMALL;
      this.renderer.addClass(this.iconElement, this.toggleClass);
    } else {
      this.renderer.removeClass(this.iconElement, this.toggleClass);
      this.toggleClass = ScreenClasses.FULL;
    }
  }

  ngAfterViewInit() {
    this.iconElement = this.el.nativeElement.childNodes[0];
    this.verifyFullScreen()
  }

}
