import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

export const simpleAPI = ['http://api.ipify.org/?format=json', 'https://s3.eu-central-1.amazonaws.com'];

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private simpleAPI = simpleAPI
  private API_URL = environment.apiUrl;
  private DICTIONARY_PATH = './assets/i18n/';
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = localStorage.getItem('id_token');
    let request;
    if (!this.simpleAPI.includes(req.url) && !req.url.includes(this.simpleAPI[1])) {
        request = req.clone({
        url: req.url.includes(this.DICTIONARY_PATH) ? [req.url].join('') : `${this.API_URL}${req.url}`,
        // headers: req.url.includes(this.API_URL) ? req.headers.set('authorization', `Bearer ${accessToken}`) : req.headers
        headers: req.headers.set('authorization', `Bearer ${accessToken}`)
      })
    } else {
      request = req.clone({
        url: `${req.url}`
      });
    }
    return next.handle(request);
  }
}
