import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth-guard.service';

// Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_ROUTES: Routes = [
    {
        path: 'callback',
        loadChildren: () => import('../../common/callback/callback.module').then(m => m.CallbackModule)
    },
    {
        path: 'error',
        loadChildren: () => import('../../common/error/error.module').then(m => m.ErrorModule)
    },
    {
        path: 'error-login',
        loadChildren: () => import('../../common/error/error.module').then(m => m.ErrorModule)
    },
    {
        path: 'verify',
        loadChildren: () => import('../../common/error/error.module').then(m => m.ErrorModule)
    },
    {
        path: 'not-connected',
        loadChildren: () => import('../../common/error/error.module').then(m => m.ErrorModule)
    },
    {
        path: 'customer-error',
        loadChildren: () => import('../../customer/pages/error/error-page.module').then(m => m.ErrorPageModule)
    },
    {
        path: 'terms',
        loadChildren: () => import('../../common/terms/terms.module').then(m => m.TermsModule)
    },
    {
        path: 'privacy',
        loadChildren: () => import('../../common/privacy/privacy.module').then(m => m.PrivacyModule)
    },
];
