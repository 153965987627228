import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-automcompleter',
  templateUrl: './automcompleter.component.html',
  styleUrls: ['./automcompleter.component.scss']
})
export class AutomcompleterComponent implements OnInit, OnDestroy {
  @Input() placeholder = '';
  @Input() autcompoleteData: any[] = [];
  @Input() isEmpty = false;
  @Output() onChangeValue: EventEmitter<string> = new EventEmitter<string>();
  @Output() onItemSelected: EventEmitter<any> = new EventEmitter<any>();

  controlValue: FormControl = new FormControl('');
  onChangeSubscription: Subscription;
  isOpen = false;
  isSelected = false;
  preselectedIndex = 0;
  selectedByKey = false;

  constructor() {}

  @HostListener('keydown', ['$event'])
    onKeyDown(e) {
      if (this.autcompoleteData && this.autcompoleteData.length) {
        switch (e.keyCode) {
          case 40:
            this.preselectedIndex = this.preselectedIndex === this.autcompoleteData.length - 1 ? 0 : this.preselectedIndex + 1;
            break;
          case 38:
            this.preselectedIndex = this.preselectedIndex === 0 ? this.autcompoleteData.length - 1 : this.preselectedIndex - 1;
            break;
          case 13:
            if (this.isOpen) {
              this.onItemSelected.emit(this.autcompoleteData[this.preselectedIndex]);
              this.selectedByKey = true;
              this.controlValue.setValue(this.autcompoleteData[this.preselectedIndex].name);
              this.isOpen = false;
              this.preselectedIndex = 0;
            };
            break;
          default:
            break;
        }
      }
    }

  ngOnInit(): void {
    this.onChangeSubscription = this.controlValue.valueChanges.pipe(debounceTime(400)).subscribe((value: string) => {
      if (!this.isSelected && !this.selectedByKey) {
        this.isOpen = true;
      } else {
        this.isOpen = false;
      }
      this.onChangeValue.emit(value);
      this.selectedByKey = false
      this.isSelected = false;
    })
  }

  selectItem = (item: any) => {
    this.onItemSelected.emit(item);
    this.isOpen = false;
    this.controlValue.setValue(item.name);
    this.isSelected = true
  }

  ngOnDestroy(): void {
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe();
    }
  }

}
