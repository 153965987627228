import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Language } from '../constants/language.constants';
import { TranslateSettingsService } from '../services/translate-settings.service';

@Component({
  selector: 'app-choose-language-pop-up',
  templateUrl: './choose-language-pop-up.component.html',
  styleUrls: ['./choose-language-pop-up.component.scss']
})
export class ChooseLanguagePopUpComponent implements OnInit {
  readonly language = Language;

  public step = 1;
  private myLanguage: Language;

  constructor(
    private activeModal: NgbActiveModal,
    private translateSettingsService: TranslateSettingsService,
  ) { }

  ngOnInit(): void {
  }

  chooseLanguage(language: Language) {
    this.myLanguage = language;
    this.translateSettingsService.changeLanguage(language);
    this.activeModal.close(this.myLanguage);
    // ++this.step;
  }

  // next() {
  //   this.activeModal.close(this.myLanguage);
  // }
}
