<hr>
<div class="mobile-common">
  <div class="content mt-4">
    <div class="mobile-view" *ngFor="let point of pointOflist">
      <container-element [ngSwitch]="point.type">
        <some-element *ngSwitchCase="contentTableTypes.FileNamePipe">
          <p class="table-header"> {{ point.title | translate }} </p>
          <p> {{ (point?.value | objectName: data) || (point?.otherValue | objectName: data | fileName) }} </p>
        </some-element>
        <some-element *ngSwitchCase="contentTableTypes.TEXT">
          <p class="table-header"> {{ point.title | translate }} </p>
          <p> {{ point?.value | objectName: data : point?.defaultValue : point?.otherValue }} </p>
        </some-element>
        <some-element *ngSwitchCase="contentTableTypes.ICON">
          <p class="table-header"> {{ point.title | translate }} </p>
          <div *ngIf="data[point?.value]; else dangerBlock" class="text-success ft-check text-center"></div>
          <ng-template #dangerBlock>
            <div class="text-danger text-center ft-alert-triangle"></div>
          </ng-template>
        </some-element>
        <some-element *ngSwitchCase="contentTableTypes.DATE">
          <p class="table-header"> {{ point.title | translate }} </p>
          <p> {{ point?.value | objectName: data | date }} </p>
        </some-element>
        <some-element *ngSwitchCase="contentTableTypes.InvoiceProgres">
          <app-invoices-progress [value]="data.status"></app-invoices-progress>
        </some-element>
        <some-element *ngSwitchCase="contentTableTypes.FailedCasePipe">
          <p class="table-header"> {{ point.title | translate }} </p>
          <p> {{ point.value | objectName: data | failedCase }} </p>
        </some-element>
        <some-element *ngSwitchCase="contentTableTypes.FailedCheckPipe">
          <p class="table-header"> {{ point.title | translate }} </p>
          <p> {{ point.value | objectName: data | failedCheck }} </p>
        </some-element>
        <some-element *ngSwitchCase="contentTableTypes.EmailPipe">
          <p class="table-header"> {{ point.title | translate }} </p>
          <p> {{ point.value | objectName: data | senderEmails }} </p>
        </some-element>
        <some-element *ngSwitchCase="contentTableTypes.AddressPipe">
          <p class="table-header"> {{ point.title | translate }} </p>
          <p> {{ point?.value | objectName: data | address }} </p>
        </some-element>
        <some-element *ngSwitchCase="contentTableTypes.ICONreverse">
          <p class="table-header"> {{ point.title | translate }} </p>
          <div>
            <div *ngIf="data[point?.value]; else successBlock" class="text-warning ft-alert-triangle"></div>
            <ng-template #successBlock>
              <div class="text-success ft-check"></div>
            </ng-template>
          </div>
        </some-element>
        <some-element *ngSwitchCase="contentTableTypes.IconStateSupplier">
          <p class="table-header"> {{ point.title | translate }} </p>
          <div>
            <div *ngIf="data.prio && data.days_since_last_invoice >= 30; else elseBlock"
              [ngClass]="{'text-danger': data.days_since_last_invoice >= 60, 'text-warning': data.days_since_last_invoice >= 30}"
              [ngbPopover]="popContent" triggers="mouseenter:mouseleave"
              [popoverTitle]="data.days_since_last_invoice >= 60 ? '60 days warning - Inactivity' : '30 days warning - Inactivity'"
              class="ft-alert-triangle text-center">
            </div>
            <ng-template #popContent>
              <div>{{ 'Customer' | translate }}: {{ data?.lastInvoice?.customer?.name }}</div>
              <div>{{ 'Invoice ID' | translate }}: {{ data?.lastInvoice?.invoice_id }}</div>
              <div>{{ 'Last Invoice' | translate }}: {{ data?.lastInvoice?.created_at | date }}</div>
              <div>{{ 'Sign up date' | translate }}: {{ data?.lastInvoice?.customer?.created_at | date }}</div>
            </ng-template>
            <ng-template #elseBlock>
              <div class="text-success text-center ft-check"></div>
            </ng-template>
          </div>
        </some-element>
        <some-element *ngSwitchCase="contentTableTypes.PeppolProgres">
          <app-invoices-progress [value]="data.status"></app-invoices-progress>
        </some-element>
        <some-element *ngSwitchCase="contentTableTypes.Button">
          <button class="bellowEl" [ngClass]="point.class" (click)="emitDataTwo(data)">{{ point.buttonText | translate
            }}</button>
        </some-element>
        <some-element *ngSwitchCase="contentTableTypes.multiButton">
          <div class="btn-group bellowEl">
            <button *ngIf="!data.auth0" (click)="emitDataOne(data._id)" class="btn btn-raised btn-danger m-0 bellowEl">
              {{ 'Delete' | translate }}
            </button>
            <button *ngIf="data.type === userTypes.openChannel" (click)="emitDataTwo(data._id)"
              class="btn btn-raised btn-success m-0 bellowEl">
              {{ 'Resend' | translate }}
            </button>
          </div>
        </some-element>
        <some-element *ngSwitchCase="contentTableTypes.multiButtonUserFailed">
          <button class="btn btn-raised btn-primary bellowEl" [disabled]="data?.isResolve"
            (click)="emitDataOne({id: data?._id, event: $event})">{{ data?.isResolve ? 'Resolved' : 'Mark as resolved'
            }}</button>
        </some-element>
        <some-element *ngSwitchCase="contentTableTypes.CheckboxChange">
          <p class="table-header"> {{ point.title | translate }} </p>
          <div>
            <input type="checkbox" (change)="rowSelect(data._id, $event.target.checked)" class="mb-2">
          </div>
        </some-element>
        <some-element *ngSwitchCase="contentTableTypes.CheckboxAutomated">
          <p class="table-header"> {{ point.title | translate }} </p>
          <div>
            <input type="checkbox" [(ngModel)]="data[point?.value]"
              (change)="checkParam(data, $event.target.checked, point.checkParamString)" [checked]="data[point.value]"
              [disabled]="checkDisabled(data._id)" class="mb-3">
          </div>
        </some-element>
        <some-element *ngSwitchCase="contentTableTypes.stageOfFailedCase">
          <p class="table-header"> {{ point.title | translate }} </p>
          <p> {{ stageOfFailedCase(data[point?.value]) }} </p>
        </some-element>
        <div class="info-link-mobile">
          <some-element *ngSwitchCase="contentTableTypes.INFO" class="link">
            <p (click)="infoUrl(data._id)" *ngIf="curentlyRoute !== '/customers'; else customerRequirementsAlert">
              <i class="icon-info info-link"></i>
            </p>
            <ng-template #customerRequirementsAlert>
              <p (click)="modalCustomerInfo(data)">
                <i class="icon-info info-link"></i>
              </p>
            </ng-template>
          </some-element>
        </div>
      </container-element>
    </div>
  </div>