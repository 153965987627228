<main class="scroll-sing-up">
  <app-sign-up-header></app-sign-up-header>
  <div class="background-wrap">
    <p class="welcome-text text-white">{{ 'Welcome to' | translate }} eSynCloud</p>
    <div class="container sign-up-container">
      <div class="row content parent-sing-up">
        <div class="col-md-6 welcome-container text-to-regist"></div>
        <div class="col-md-6 form-container">
          <div class="form-border">
            <div class="row m-0 header-text-container">
              <div class="side"></div>
              <div class="center header-text text-white">
                <span class="p-1 ml-2 subhead-text"> {{ 'Create account' | translate }}</span>
                <span class="p-1 mr-2 subhead-text"> {{ 'eSynergetics' | translate }}</span>
              </div>
              <div class="side p-1">{{ step }}/2</div>
            </div>
            <div class="row m-0 content">
              <div class="side"></div>
              <div class="center p-0 content">
                <form [formGroup]="form" (ngSubmit)="next()" class="sign-up-form mt-2" [hidden]="step !== 1">
                  <b class="main-text mt-2">{{ 'Create your supplier account' | translate }}</b>
                  <br />
                  <b class="secondary-text mt-2">{{ 'Submit your details in order to verify you as a supplier' | translate }}</b>
                  <fieldset class="form-group mt-2">
                    <p class="input-title">{{ 'Organization Id number' | translate }}</p>
                    <input
                      type="text"
                      [placeholder]="'Organization Id number' | translate"
                      class="form-control border-primary sign-up-field"
                      id="org_id"
                      formControlName="org_id"
                    />
                    <hr class="input-line m-0" />
                    <small
                      class="m-0 text-muted danger"
                      *ngIf="form.get('org_id') && form.get('org_id').touched && form.get('org_id').invalid"
                      >{{ form | getErrorMessage: 'org_id' | translate }}</small
                    >
                  </fieldset>
                  <fieldset class="form-group">
                    <p class="input-title">{{ 'Email' | translate }}</p>
                    <input
                      type="text"
                      autocomplete="new-password"
                      [placeholder]="'Email' | translate"
                      class="form-control border-primary sign-up-field"
                      id="username"
                      formControlName="email"
                    />
                    <hr class="input-line m-0" />
                    <small
                      class="m-0 text-muted danger"
                      *ngIf="form.get('email') && form.get('email').touched && form.get('email').invalid"
                      >{{ form | getErrorMessage: 'email' | translate }}</small
                    >
                  </fieldset>
                  <fieldset class="form-group">
                    <p class="input-title">{{ 'Password' | translate }}</p>
                    <input
                      type="password"
                      autocomplete="new-password"
                      [placeholder]="'Password' | translate"
                      class="form-control border-primary sign-up-field"
                      id="password"
                      formControlName="password"
                    />
                    <hr class="input-line m-0" />
                    <small
                      class="m-0 text-muted danger"
                      *ngIf="form.get('password') && form.get('password').touched && form.get('password').invalid"
                      >{{ form | getErrorMessage: 'password' | translate }}</small
                    >
                  </fieldset>
                  <fieldset class="form-group">
                    <p class="input-title">{{ 'Password (confirm)' | translate }}</p>
                    <input
                      type="password"
                      [placeholder]="'Password (confirm)' | translate"
                      class="form-control border-primary sign-up-field"
                      id="re_password"
                      formControlName="confirmPass"
                    />
                    <hr class="input-line m-0" />
                    <small class="m-0 text-muted danger" *ngIf="form.get('confirmPass').touched && form.hasError('notSame')">{{
                      'Passwords does not match' | translate
                    }}</small>
                  </fieldset>
                  <div class="m-0 my-2 text-muted danger" *ngIf="emailCheckErr">{{ emailCheckErr }}</div>
                  <br />
                  <button type="submit" class="btn next-button btn-primary btn-round" [disabled]="!form.valid || loading">
                    {{ 'Next' | translate }}
                  </button>
                </form>
                <div *ngIf="step === 2" class="my-2 accordion">
                  <b class="main-text mt-2">{{ 'Create your supplier account' | translate }}</b>
                  <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" [activeIds]="activePanel" [destroyOnHide]="false">
                    <ngb-panel id="panel-1">
                      <ng-template ngbPanelTitle>
                        <div class="d-flex align-items-center justify-content-between" (click)="goToFirstStep($event)">
                          <p class="m-0">{{ 'Verify Supplier' | translate }}</p>
                          <div *ngIf="stepsState.first" class="text-success text-center ft-check"></div>
                        </div>
                      </ng-template>
                      <ng-template ngbPanelContent> </ng-template>
                    </ngb-panel>
                    <ngb-panel id="panel-2">
                      <ng-template ngbPanelTitle>
                        <div class="d-flex align-items-center justify-content-between" (click)="activePanel = 'panel-2'">
                          <p class="m-0">{{ 'Verify your sending address' | translate }}</p>
                          <div *ngIf="stepsState.second" class="text-success text-center ft-check"></div>
                        </div>
                      </ng-template>
                      <ng-template ngbPanelContent>
                        <p class="step-2-main-text">{{ 'Add a invoice sender email or open your domain' | translate }}</p>
                        <div class="mt-2">
                          <div *ngFor="let address of addresses; index as i" class="address-entry">
                            <p>{{ address }}</p>
                            <button class="address-btn p-0 text-primary no-outline" (click)="removeAddress(i)">-</button>
                          </div>
                        </div>
                        <div>
                          <div class="address-control">
                            <input
                              class="form-control border-primary sign-up-field no-outline"
                              type="text"
                              [placeholder]="'Invoice address' | translate"
                              [formControl]="addressControl"
                              (focus)="addressesError = false"
                            />

                            <button class="address-btn p-0 text-primary no-outline" (click)="addAddress()">+</button>
                          </div>
                          <br />
                          <small class="m-0 text-muted danger" *ngIf="addressesError">{{ 'This address already added' | translate }}</small>
                          <div class="domain-flex">
                            <div class="form-check p-0 mb-2">
                              <input
                                type="checkbox"
                                (click)="handleCheck($event.target.checked)"
                                class="form-check-input domain-checkbox"
                                id="domainCheck"
                                [checked]="openDomainChecked"
                              />
                              <label class="form-check-label step-2-main-text" for="domainCheck"
                                >{{ 'Open' | translate }} @{{ openDomain }}
                              </label>
                            </div>
                            <i
                              class="icon-info text-primary info-link"
                              [ngbPopover]="popContent"
                              placement="top"
                              triggers="mouseenter:mouseleave"
                            ></i>
                          </div>
                        </div>
                        <div class="mb-2 privacy">
                          <a href="/terms" target="_blank">Terms</a>
                          <a href="/privacy" target="_blank">Privacy</a>
                        </div>
                        <button
                          type="submit"
                          class="btn next-button btn-primary btn-round"
                          [disabled]="!addresses.length"
                          (click)="activePanel = 'panel-3'; stepsState.second = true"
                        >
                          {{ 'Next' | translate }}
                        </button>
                      </ng-template>
                    </ngb-panel>
                    <ngb-panel id="panel-3">
                      <ng-template ngbPanelTitle>
                        <div class="d-flex align-items-center justify-content-between" (click)="activePanel = 'panel-3'">
                          <p class="m-0">{{ 'Verify supplier details' | translate }}</p>
                          <div *ngIf="stepsState.third" class="text-success text-center ft-check"></div>
                        </div>
                      </ng-template>
                      <ng-template ngbPanelContent>
                        <p class="step-3-main-text">{{ 'Check and verify your details, adjust if required' | translate }}</p>

                        <app-verify-suppliers-details
                          [addressInformation]="addressInformation"
                          [accountDetails]="accountDetails"
                          [isNewUser]="!supplier?.isOwned"
                          (next)="goToLastStep($event)"
                          [decodeInfo]="decodeInfo"
                        ></app-verify-suppliers-details>
                        <!-- temporary, delete after adding fourth step -->
                        <div class="m-0 my-2 text-muted danger" *ngIf="signUpErr">{{ signUpErr | translate }}</div>
                      </ng-template>
                    </ngb-panel>
                    <!-- temporary solution -->
                    <!-- <ngb-panel id="panel-4">
                      <ng-template ngbPanelTitle>
                        <div class="d-flex align-items-center justify-content-between">
                          <p class="m-0">{{ 'Contact details and login' | translate }}</p>
                          <div *ngIf="stepsState.forth" class="text-success text-center ft-check"></div>
                        </div>
                      </ng-template>
                      <ng-template ngbPanelContent>
                        <p class="step-2-main-text">{{ 'Add your contact details' | translate }}</p>
                        <form [formGroup]="contactForm">
                          <fieldset class="form-group">
                            <input type="email" [placeholder]="'Contact Email' | translate"
                                   class="form-control border-primary sign-up-field" id="contact_email"
                                   formControlName="email">
                            <hr class="input-line m-0">
                            <small class="m-0 text-muted danger"
                                   *ngIf="form.get('email') && form.get('email').touched && form.get('email').invalid">{{ getErrorMessage('email') }}</small>
                          </fieldset>
                          <fieldset class="form-group">
                            <input type="text" [placeholder]="'Mobile number' | translate"
                                   class="form-control border-primary sign-up-field" id="number" formControlName="number">
                            <hr class="input-line m-0">
                            <small class="m-0 text-muted danger"
                                   *ngIf="form.get('number') && form.get('number').touched && form.get('number').invalid">{{ getErrorMessage('number') }}</small>
                          </fieldset>
                          <div class="terms-check p-0 mb-2">
                            <input type="checkbox" (click)="handleTermsCheck($event.target.checked)"
                                   class="form-check-input domain-checkbox" id="termsCheck">
                            <label class="form-check-label step-2-main-text"
                                   for="termsCheck"></label>
                            <div>{{ 'I agree to the' }}
                              <a [routerLink]="'/terms'"> {{ 'Terms and Conditions' | translate }}</a>
                              {{ 'and' | translate }}
                              <a [routerLink]="'/privacy'">{{ 'privacy policy.' | translate }}</a></div>
                          </div>
                          <button
                            type="submit"
                            class="btn next-button btn-primary btn-round"
                            [disabled]="!termsChecked || !checkContactForm"
                            (click)="signUp()"

                          >{{ 'Sign up' | translate }}
                          </button>
                          <br>
                          <div class="m-0 my-2 text-muted danger" *ngIf="signUpErr">{{ signUpErr | translate }}</div>
                        </form>
                      </ng-template>
                    </ngb-panel> -->
                  </ngb-accordion>
                </div>
              </div>
              <div class="side"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #popContent>
      <div class="info-popover">{{ 'Enables all users from your organization to send invoices to eSynCloud' | translate }}</div>
    </ng-template>
  </div>
</main>
