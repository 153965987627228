import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';


@Pipe({
  name: 'objectName'
})
export class ObjectNamePipe implements PipeTransform {

  transform(value: string, item: any, defaultValue: string = '', otherValue: string = '' ): unknown {
    const valueOfObject = get(item, value, defaultValue);
    if (otherValue && !valueOfObject) {
      return get(item, otherValue, defaultValue)
    }
    return valueOfObject;
  }

}
