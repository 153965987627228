import {NgModule} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule} from "@angular/common";
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";

import {PasswordResetRoutingModule} from './password-reset.routing.module';
import {PasswordResetComponent} from "./password-reset.component";
import {AuthService} from "../auth/auth.service";
import {UserService} from "../services/user.service";


@NgModule({
  imports: [
    NgbModule,
    CommonModule,
    PasswordResetRoutingModule,
    TranslateModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
  ],
  providers: [
    AuthService,
    UserService,
  ],
  declarations: [
    PasswordResetComponent
  ],
})

export class PasswordResetModule {}
