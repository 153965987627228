import {NgModule} from '@angular/core';
import {NgbAccordionModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import { FileUploadModule } from 'ng2-file-upload';
import {TranslateModule} from '@ngx-translate/core';

import {FooterComponent} from './footer/footer.component';
import {NavbarComponent} from './navbar/navbar.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {ToggleFullscreenDirective} from './directives/toggle-fullscreen.directive';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SignUpModule} from './sign-up/sign-up.module';
import {PasswordResetModule} from './password-reset/password-reset.module';

import { CompanyService } from 'app/shared/services/company.service';
import { UserService } from 'app/shared/services/user.service';
import { CustomerService } from 'app/shared/services/customer.service';
import { InvoicesService } from 'app/shared/services/invoices.service';
import { PeppolService } from 'app/shared/services/peppol.service';
import { JsonService } from 'app/shared/services/json.service';
import { FindsyService } from 'app/shared/services/findsy.service';
import { EmailService } from 'app/shared/services/email.service';
import { TranslateSettingsService } from './services/translate-settings.service';
import { FormService } from './services/form.service';
import { AlertsService } from './services/alerts.service';
import { OcrmlService } from './services/ocrml.service';
import { UploadInvoiceComponent } from './upload-invoice/upload-invoice.component';
import { AutomcompleterComponent } from './automcompleter/automcompleter.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { PaginationComponent } from './pagination/pagination.component';
import { InvoicesProgressComponent } from './progress/invoices.progress.component';
import { FileNamePipeModule } from 'app/pipes/file-name.pipe';
import { MobileViewForTableComponent } from './mobile-view-for-table/mobile-view-for-table.component';
import { ObjectNamePipe } from './pipes/object-name.pipe';
import { TableComponent } from './table/table.component';
import { SortHeaderModule } from './directives/sort-header.directive';
import { AddressPipeModule } from 'app/pipes/address.pipe';
import { FailedCasePipe } from 'app/pipes/failed-case.pipe';
import { FailedCheckPipe } from 'app/pipes/failed-check.pipe';
import { SenderEmailsPipe } from 'app/pipes/senderEmails.pipe';
import { TutorialOdfComponent } from './tutorial-odf/tutorial-odf.component';
import { ChangeUserTypeComponent } from './change-user-type/change-user-type.component';
import { CheckSellablePipe } from 'app/pipes/check-sellable.pipe';
import { NewDatePipe } from 'app/pipes/new-date.pipe';
import { InvoiceStatusPipe } from 'app/pipes/invoice-status.pipe';
import { StatusSourcePipe } from 'app/pipes/status-source.pipe';
import { CounterDateComponent } from './counter-date/counter-date.component';
import { BankAlertComponent } from './odf-pop-ups/bank-alert/bank-alert.component';
import { InvoiceUploadAlertComponent } from './invoice-upload-alert/invoice-upload-alert.component';
import { AgreementOdfService } from './services/agreement-odf.service';
import { AutomatedOptionComponent } from './odf-pop-ups/automated-option/automated-option.component';
import { OdfAlertMessageComponent } from './odf-pop-ups/odf-alert-message/odf-alert-message.component';
import { ShareContractComponent } from './odf-pop-ups/share-contract/share-contract.component';
import { MainOdfPopUpComponent } from './odf-pop-ups/main-odf-pop-up/main-odf-pop-up.component';
import { ChooseLanguagePopUpComponent } from './choose-language-pop-up/choose-language-pop-up.component';
import { OdfBasePopUpComponent } from './odf-pop-ups/odf-base-pop-up/odf-base-pop-up.component';
import { NewAmountPipe } from 'app/pipes/new-amount.pipe';

@NgModule({
  exports: [
    CommonModule,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ToggleFullscreenDirective,
    UploadInvoiceComponent,
    AutomcompleterComponent,
    PaginationComponent,
    InvoicesProgressComponent,
    MobileViewForTableComponent,
    TableComponent,
    TutorialOdfComponent,
    CounterDateComponent,
    AutomatedOptionComponent,
    FailedCasePipe,
    FailedCheckPipe,
    SenderEmailsPipe,
    CheckSellablePipe,
    NewDatePipe,
    InvoiceStatusPipe,
    StatusSourcePipe,
    NewAmountPipe,
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    SignUpModule,
    PasswordResetModule,
    NgbAccordionModule,
    FileUploadModule,
    ClickOutsideModule,
    FileNamePipeModule,
    SortHeaderModule,
    AddressPipeModule,
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ToggleFullscreenDirective,
    UploadInvoiceComponent,
    AutomcompleterComponent,
    PaginationComponent,
    InvoicesProgressComponent,
    MobileViewForTableComponent,
    CounterDateComponent,
    AutomatedOptionComponent,
    ObjectNamePipe,
    FailedCasePipe,
    TableComponent,
    FailedCheckPipe,
    SenderEmailsPipe,
    CheckSellablePipe,
    NewDatePipe,
    InvoiceStatusPipe,
    StatusSourcePipe,
    NewAmountPipe,
    TutorialOdfComponent,
    ChangeUserTypeComponent,
    BankAlertComponent,
    InvoiceUploadAlertComponent,
    OdfAlertMessageComponent,
    ShareContractComponent,
    MainOdfPopUpComponent,
    ChooseLanguagePopUpComponent,
    OdfBasePopUpComponent,
  ],
  providers: [
    CompanyService,
    UserService,
    CustomerService,
    InvoicesService,
    PeppolService,
    JsonService,
    FindsyService,
    EmailService,
    TranslateSettingsService,
    FormService,
    AlertsService,
    OcrmlService,
    AgreementOdfService,
  ],
  entryComponents: [
    InvoicesProgressComponent,
  ]
})

export class SharedModule { }
