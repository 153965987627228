import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

import { IGetPaid, IShareContract } from 'app/models/odf.model';

@Injectable({
  providedIn: 'root'
})
export class BankIdMobileService {
  private url = '/oneFlow';

  constructor(private http: HttpClient) { }

  signBankId = (data: IGetPaid): Observable<any> => (
    this.http.post<any>(`${this.url}/contract`, data)
  );

  getContractLink = (): Observable<any> => (
    this.http.get<any>(`${this.url}/contract/link`).pipe(pluck('data'))
  );

  shareByEmail = (data: IShareContract): Observable<any> => (
    this.http.post<any>(`${this.url}/contract/share`, data).pipe(pluck('data'))
  );
}
