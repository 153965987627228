import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { IGetPaid, IShareContract } from 'app/models/odf.model';
import { AlertsService } from './alerts.service';
import { BankIdMobileService } from './bank-id-mobile.service';
import { BankAlertComponent } from '../odf-pop-ups/bank-alert/bank-alert.component';
import { IsShowBtnFeatureService } from 'app/client/esyn-finance/services/is-show-btn-feature.service';
import { ShareContractComponent } from '../odf-pop-ups/share-contract/share-contract.component';
import { OdfAlertMessageComponent } from '../odf-pop-ups/odf-alert-message/odf-alert-message.component';
import { ODF_SIGN_CHECKER } from '../../client/esyn-finance/constants/pop-up.constant';

interface IAlertMessageData {
  shareContract: ODF_SIGN_CHECKER,
  recreateContract: boolean,
}

@Injectable()
export class AgreementOdfService {
  constructor(
    private modalService: NgbModal,
    private alertService: AlertsService,
    private bankIdMobileService: BankIdMobileService,
    public isShowBtnFeatureService: IsShowBtnFeatureService,
  ) { }

  agreement() {
    const swedishBank = this.modalService.open(BankAlertComponent, { windowClass: 'myCustomModalClass modalWindowByCenter' })
    swedishBank.componentInstance.alertMessage = this.alertMessage.bind(this)
    swedishBank.result.then((data: IGetPaid) => {
      if (data) {

        const odfData = {
          legalName: `${data.firstName} ${data.lastName}`,
          phoneNumber: data.phoneNumber,
          email: data.email,
          personalID: data.personalID
        }

        this.bankIdMobileService.signBankId(odfData)
          .subscribe(
            (url: string) => {
              window.open(url, '_blank');
              this.isShowBtnFeatureService.setStateButton(false);
            },
            () => this.alertService.error(`Something went wrong`)
          )
      }
    })
  }

  sharebyEmail() {
    const shareData = this.modalService.open(ShareContractComponent, { windowClass: 'myCustomModalClass modalWindowByCenter' })
    shareData.componentInstance.alertMessage = this.alertMessage.bind(this)
    shareData.result.then((data: IShareContract) => {
      if (data) {

        const shareOdfData = {
          legalName: `${data.firstName} ${data.lastName}`,
          email: data.email
        }

        this.bankIdMobileService.shareByEmail(shareOdfData)
          .subscribe(() => {
            this.alertService.success(`Contract is sharing success. We sent email to ${shareOdfData.legalName} about contract`);
            this.isShowBtnFeatureService.setStateButton(false);
          },
            () => this.alertService.error(`Something went wrong`)
          )
      }
    })

  }

  alertMessage(data?: IAlertMessageData) {
    const alert = this.modalService.open(OdfAlertMessageComponent, { windowClass: "myCustomBigModal" })
    alert.componentInstance.agreement = this.agreement.bind(this)
    alert.componentInstance.sharebyEmail = this.sharebyEmail.bind(this)

    if (data) {
      alert.componentInstance.shareContract = data.shareContract
      alert.componentInstance.recreateContract = data.recreateContract
    } else {
      alert.componentInstance.wasOpen = true
    }
  }
}
