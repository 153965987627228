import {NgModule, Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'address' })
export class AddressPipe implements PipeTransform {
  transform(address: any): string {
    return address.street + ', ' + address.zip + ' ' + address.city + ', ' + address.country
  }
}

@NgModule({
  declarations: [AddressPipe],
  exports: [AddressPipe]
})

export class AddressPipeModule { }
