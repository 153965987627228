import { Component, Input, OnInit } from '@angular/core';
import { InvoicesStatuses, KeerosInvoicesStatuses, PeppolStatuses } from 'app/shared/constants/invoices.constants';
import { IInvoiceStatus } from 'app/models/invoices.model';
import { ProgressKeerosInvoicesStatuses, KeerosDefaultStatus } from '../../client/esyn-finance/models/keeros.model';
import { InvoiceStatuses } from 'app/customer/constants/invoice-statuses.constants';

export enum TypeOfProgressBar {
  KeerosInvoicesStatuses = 'KeerosInvoicesStatuses',
  InvoicesStatuses = 'InvoicesStatuses',
  ProgressKeerosInvoicesStatuses = 'ProgressKeerosInvoicesStatuses',
  PeppolStatuses = 'PeppolStatuses'
}

const statusesTypes = {
  KeerosInvoicesStatuses,
  InvoicesStatuses,
  ProgressKeerosInvoicesStatuses,
  PeppolStatuses
}
@Component({
  selector: 'app-invoices-progress',
  templateUrl: './invoices.progress.component.html',
  styleUrls: ['./invoices.progress.component.scss'],
})

export class InvoicesProgressComponent implements OnInit {
  @Input() value: string;
  @Input() type: TypeOfProgressBar = TypeOfProgressBar.InvoicesStatuses;
  statuses: IInvoiceStatus[] = [];

  currentStatus: IInvoiceStatus;

  constructor() {}

  ngOnInit() {
    if (this.value === InvoiceStatuses.PDF_FORWARD) {
      this.type = TypeOfProgressBar.InvoicesStatuses
    }

    if (this.value === KeerosDefaultStatus.title) {
      this.currentStatus = KeerosDefaultStatus
    } else {
      this.statuses = statusesTypes[this.type];
      this.currentStatus = this.statuses
        .find(({ value }) => value === this.value);
      if (!this.currentStatus) {
        this.currentStatus = KeerosDefaultStatus;
      }
    }
  }

}
