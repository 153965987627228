<main>
  <div class="modal-header">
    <h5 class="modal-title">{{ 'Change user type' | translate }}</h5>
  </div>
  <div class="modal-body">
    <div>
      <label for="name">{{ 'Select user type' | translate }}:</label>
      <div>
        <select [formControl]="typeValue">
          <option
            *ngFor="let type of userTypes; trackBy: trackByForTypes"
            [value]="type"
          >{{ type | titlecase }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="btn"
      (click)="changeType()"
    >{{ 'Change user type' | translate }}</button>
  </div>
</main>
