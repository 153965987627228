import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable()
export class FilterService {
  filter: any = {};
  activeCol = new Subject();
  uniqueUrl: string[] = [
    '/admin/emails',
    '/admin/domains/allowed',
    '/admin/domains/open-chanel',
    '/admin/users/list',
    '/eSynPay/invoices/list',
    '/admin/failed-cases',
  ];

  constructor(private router: Router) {
    if (!this.uniqueUrl.includes(this.router.url) && !this.router.url.includes('/admin/domains/detail/')) {
      this.filter.getAll = this.router.url.includes('/admin') ? true : false;
    }
  }

  autocomplit(selectedItem, field: string) {
    if (selectedItem) {
      this.filter[field] = selectedItem._id || selectedItem;
    } else {
      delete this.filter[field];
    }
  }

  sortInfo(event) {
    this.filter = Object.assign(this.filter, event);
    this.activeCol.next(event.sortCol);
  }
}
