<div id="action">
  <div class="row">
    <div class="col-sm-12">
      <div class="card radius-wrapper-card">
        <div class="card-header">
          <h4 class="card-title pull-left">{{title | translate}}</h4>
            <ng-content select="[button]"></ng-content>
        </div>

          <ng-content select="[body]"></ng-content>

        <div class="card-body scroll-table-y">
          <div class="card-block">
              <ng-content select="[table]"></ng-content>
            <div class="pagination-wrap" *ngIf="total > limit && !loading">
              <ngb-pagination
                [disabled]="disabled"
                size="sm"
                [(page)]="page"
                [(pageSize)]="limit"
                maxSize="10"
                [rotate]="true"
                (pageChange)="pageChange($event)"
                [collectionSize]="total">
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>