export interface IGetPaid {
  firstName?: string,
  lastName?: string,
  legalName?: string,
  phoneNumber: string,
  email: string,
  personalID: string
}

export interface IShareContract {
  firstName?: string,
  lastName?: string,
  email: string
}

export interface IKeerosBankData {
  bankAccountNo: string;
  bankClearingNo: string;
};

export enum OdfStatuses {
  ELIGIBLE = 'ELIGIBLE',
  NOT_ODF = 'NOT_ODF',
};

export function arrayOfODFStatuses(): any[] {
  const statuses = [
    { value: '', title: 'All'},
    ...Object.values(OdfStatuses)
      .map((item) => ({ value: item, title: OdfStatuses[item].replace(/[^A-Za-z]/g, ' ') }))
  ];
  return statuses;
};
