import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ODF_SIGN_CHECKER } from 'app/client/esyn-finance/constants/pop-up.constant';
import { ODF_ERROR } from 'app/shared/constants/errors.constants';
import { AgreementOdfService } from 'app/shared/services/agreement-odf.service';
import { AlertsService } from 'app/shared/services/alerts.service';
import { BankIdMobileService } from 'app/shared/services/bank-id-mobile.service';

@Component({
  selector: 'app-main-odf-pop-up',
  templateUrl: './main-odf-pop-up.component.html',
  styleUrls: ['./main-odf-pop-up.component.scss']
})
export class MainOdfPopUpComponent implements OnInit {
  readonly ODF_SIGN_CHECKER = ODF_SIGN_CHECKER;

  @Input() shareContract: ODF_SIGN_CHECKER;
  @Input() recreateContract: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private agreementOdfService: AgreementOdfService,
    private bankIdMobileService: BankIdMobileService,
    private alertService: AlertsService,
  ) { }

  ngOnInit(): void {
  }

  closeModal(): void {
    this.activeModal.close()
  }

  toOdfAlertMessage(): void {
    this.activeModal.close()
    this.agreementOdfService.alertMessage({
      shareContract: this.shareContract,
      recreateContract: this.recreateContract
    })
  }

  toSignUp(): void {
    this.activeModal.close()
    this.agreementOdfService.agreement()
  }

  toShareByEmail(): void {
    this.activeModal.close()
    this.agreementOdfService.sharebyEmail()
  }

  goToOdfContract(): void {
    this.bankIdMobileService.getContractLink()
      .subscribe((url: string) => window.open(url, '_blank'),
        ({ data }) => {
          const errorText = data.error === ODF_ERROR.ONE_FLOW_CONTRACT_NOT_FOUND
            ? 'Dear user. You don\'t signed ODF contract yet'
            : 'Something went wrong'

          this.alertService.error(errorText)
        })
  }

}
