import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { isShowUploadTutorial } from 'app/client/esyn-finance/constants/pop-up.constant';
import { OdfBasePopUpComponent } from 'app/shared/odf-pop-ups/odf-base-pop-up/odf-base-pop-up.component';
import { AlertsService } from 'app/shared/services/alerts.service';
import { UserService } from 'app/shared/services/user.service';
import { BehaviorSubject } from 'rxjs';
import { UploadTutorialWindowComponent } from '../upload-tutorial-window/upload-tutorial-window.component';

// Tutorial steps
export enum TutorialUploadSteps {
  UPLOAD = 'UPLOAD',
  NAME = 'NAME',
  SIZE = 'SIZE',
  CUSTOMER = 'CUSTOMER',
  STATUS = 'STATUS',
  ACTIONS = 'ACTIONS'
}
@Injectable({
  providedIn: 'root'
})
export class UploadTutorialService {
  // Constant
  readonly arrayTutorialSteps = Object.values(TutorialUploadSteps);
  readonly isShowUploadTutorial = isShowUploadTutorial;

  // Settings for pop-up
  tutorialModalOption: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  }

  // State of tutorial
  isUploadTutorial = new BehaviorSubject<boolean>(false);

  // Handle steps
  currentStep = TutorialUploadSteps[this.arrayTutorialSteps[0]];
  currentIndexStep = 0;

  constructor(
    public router: Router,
    public modalService: NgbModal,
    public userService: UserService,
    public alertService: AlertsService
  ) { }

  nextStep = () => {
    if (this.isLastStep) { // If step was last we finished show tutorial
      this.reset();
      this.userService.editMyUser({ isTutorialShown: true }).subscribe();
      return;
    } else if (this.currentIndexStep === 1 && window.innerWidth < 1001) {
      // For small window we can see column 'Size'. This condition check it
      this.currentIndexStep += 2;
    } else { // For others cases go to next step
      this.currentIndexStep = ++this.currentIndexStep;
    }

    this.updateStep();
  }

  backStep = () => {
    if (this.isFirstStep) { // We can't go back if step is first
      this.reset();
      return;
    } else if (this.currentIndexStep === 3 && window.innerWidth < 1001) {
      // For small window we can see column 'Size'. This condition check it
      this.currentIndexStep -= 2;
    } else { // For others cases go to next step
      this.currentIndexStep = --this.currentIndexStep;
    }

    this.updateStep();
  }

  // Close tutorial
  reset = () => {
    this.currentIndexStep = 0;
    this.currentStep = TutorialUploadSteps[this.arrayTutorialSteps[0]];
    this.setStateUploadTutorial(false);
  }

  // Go to new step
  updateStep = () => {
    this.currentStep = TutorialUploadSteps[this.arrayTutorialSteps[this.currentIndexStep]];
  }

  // Modal suggest open tutorial, then show pop-up which contain description
  showTutorial = () => {
    const popUpOdflRef = this.modalService
      .open(OdfBasePopUpComponent, { windowClass: 'myCustomSmallModal modalWindowByCenter' });
    popUpOdflRef.componentInstance.windowData = this.isShowUploadTutorial;
    popUpOdflRef.componentInstance.isOdfPopUp = false;
    popUpOdflRef.result.then((value: string) => {
      if (value === this.isShowUploadTutorial.button1) {
          this.modalService.open(UploadTutorialWindowComponent, this.tutorialModalOption)
            .result.then(() => this.router.navigate(['invoices', 'upload']));

          this.setStateUploadTutorial(true);
        } else {
          this.userService.editMyUser({ isTutorialShown: true }).subscribe();
        }
      })
  }

  // Set tutorial state
  public setStateUploadTutorial = (value: boolean) => this.isUploadTutorial.next(value);

  // Get tutorial state
  get stateUploadTutorial(): boolean {
    return this.isUploadTutorial.getValue();
  }

  // Handle first step
  get isFirstStep() {
    return this.currentIndexStep === 0
  };

  // Handle last step
  get isLastStep() {
    return this.currentIndexStep === this.arrayTutorialSteps.length - 1
  };
}
