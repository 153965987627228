import {NgModule} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule} from "@angular/common";
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";

import {SignUpRoutingModule} from './sign-up.routing.module';
import {SignUpComponent} from "./sign-up.component";
import {AuthService} from "../auth/auth.service";
import {CompanyService} from "../services/company.service";
import {UserService} from "../services/user.service";
import {VerifyComponent} from "./components/verify/verify.component";
import { SignUpHeaderComponent } from './components/sign-up-header/sign-up-header.component';
import { VerifySuppliersDetailsComponent } from './components/verify-suppliers-details/verify-suppliers-details.component'
import { GetErrorMessagePipe } from 'app/pipes/get-error-message.pipe';



@NgModule({
  imports: [
    NgbModule,
    CommonModule,
    SignUpRoutingModule,
    TranslateModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
  ],
  providers: [
    AuthService,
    UserService,
    CompanyService
  ],
  entryComponents: [VerifyComponent],
  declarations: [
    SignUpComponent,
    VerifyComponent,
    SignUpHeaderComponent,
    VerifySuppliersDetailsComponent,
    GetErrorMessagePipe
  ],
})

export class SignUpModule {}
