<main class="p-2">
  <div>
    <h1 class="text-center mb-2"> {{ 'Success' | translate | uppercase}} </h1>
  </div>
  <h4 class="text-center mb-2">
    {{ 'Your invoice was successfully uploaded! You can see the invoice status here' | translate}}
  </h4>
  <div class="d-flex justify-content-center mt-4">
    <button type="submit" class="btn btn-raised btn-success" (click)="close()">
      <a [routerLink]="'/'">
        {{ 'Invoice List' | translate }}
      </a>
    </button>
    <button type="submit" class="btn btn-raised btn-success ml-2" *ngIf="isUserOdf" (click)="close()">
      <a [routerLink]="'/eSynPay/invoices/list'">
        {{ 'eSynPay list' | translate }}
      </a>
    </button>
  </div>
</main>
