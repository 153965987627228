import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs/internal/Subscription';

import { IsShowBtnFeatureService } from 'app/client/esyn-finance/services/is-show-btn-feature.service';
import { ODF_STATUS } from 'app/models/company.model';
import { AuthService } from '../auth/auth.service';
import { Language } from '../constants/language.constants';
import { AgreementOdfService } from '../services/agreement-odf.service';
import { DataTransferUserService } from '../services/data-transfer-user.service';
import { TranslateSettingsService } from '../services/translate-settings.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() isAdminMenu: boolean;

  public isAdmin: boolean;
  public isUserAndCompanyCanBeOdf: boolean;
  public notUploadPage: boolean;
  public isUserOfCompany: boolean;
  public showButtonActivateESynPay: boolean;
  navigationSubscription: Subscription;
  readonly language = Language;

  public currentLang: string;
  public uploadInvoiceButton = true;
  public isRecreateButton = false;
  public urlUploadInvoices = '/invoices/upload';

  constructor(
    public authService: AuthService,
    public translate: TranslateService,
    private userService: UserService,
    public router: Router,
    private translateSettingsService: TranslateSettingsService,
    public isShowBtnFeatureService: IsShowBtnFeatureService,
    private dataTransferUserService: DataTransferUserService,
    private agreementOdfService: AgreementOdfService
  ) {
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.notUploadPage = this.verifyUploadPage(event.url);
      }
    });
  }

  ChangeLanguage(language: string) {
    this.userService.updateUserLang({ language }).subscribe(
      ({ data }) => {
        this.currentLang = data.language;
        this.translateSettingsService.changeLanguage(data.language);
      },
      (err) => console.log(err),
      () => window.location.reload()
    );
  }

  ngOnInit() {
    const user = this.dataTransferUserService.getMeUser();

    this.currentLang = this.translateSettingsService.getLanguage(user.language);
    this.translate.setDefaultLang(this.currentLang);
    this.isUserOfCompany = !!(user?.company && !user?.customer_company);

    this.isUserAndCompanyCanBeOdf = !!(user.company?.is_odf_feature_enabled && user.company?.odf.status === ODF_STATUS.NO_ODF);

    this.userService.isAdmin().subscribe((res) => {
      this.isAdmin = res.data?.isAdmin;
    });

    this.uploadInvoiceButton = !this.router.url.includes(this.urlUploadInvoices) && !user?.customer_company;
    this.showButtonActivateESynPay = user.company?.odf?.status !== ODF_STATUS.ACTIVE && this.router.url.includes('/eSynPay');
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.uploadInvoiceButton = !event.url.includes(this.urlUploadInvoices) && !user?.customer_company;
        this.showButtonActivateESynPay = user.company?.odf?.status !== ODF_STATUS.ACTIVE && this.router.url.includes('/eSynPay');
      }
    });
  }

  verifyUploadPage = (url: string): boolean => url.includes(this.urlUploadInvoices);

  UploadInvoice() {
    this.router.navigate([this.urlUploadInvoices]);
  }

  recreateContract() {
    this.agreementOdfService.agreement();
  }

  navigateToSettings() {
    this.router.navigate(['/settings']);
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}
