import { OdfInvoiceStatuses } from 'app/client/esyn-finance/constants/keeros.constants';

const HOUR_NANOSECONDS = 1000 * 60 * 60;

export function checkSellable (invoice): boolean {
  if (invoice?.keeros_invoice || !invoice?.invoice_approve || invoice?.company.odf?.isAutoSale) {
    return false;
  }
  if (
    isExpired(invoice)
    ) {
    return false;
  }
  if (
    invoice.status === 'Approved' &&
    invoice.odf_status === OdfInvoiceStatuses.SELLABLE
  ) {
    return true;
  };
  return false;
}


export function isExpired (invoice): boolean {
  if ((Date.now() - new Date(invoice.invoice_approve).getTime()) > (22 * HOUR_NANOSECONDS)) {
    return true
  }
  return false;
};
