import { Injectable } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';

@Injectable()
export class FormService {

  constructor() { }

  public setAsTouched(group: FormGroup | FormArray) {
    group.markAsTouched()
    for (const item in group.controls) {
      if (group.controls[item] instanceof FormControl) {
        group.controls[item].markAsTouched();
      } else {
        this.setAsTouched(group.controls[item]);
      }
    }
  }

}
