import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ODF_SIGN_CHECKER } from 'app/client/esyn-finance/constants/pop-up.constant';
import { ODF_ERROR } from 'app/shared/constants/errors.constants';
import { AlertsService } from 'app/shared/services/alerts.service';
import { BankIdMobileService } from 'app/shared/services/bank-id-mobile.service';

@Component({
  selector: 'app-odf-alert-message',
  templateUrl: './odf-alert-message.component.html',
  styleUrls: ['./odf-alert-message.component.scss']
})
export class OdfAlertMessageComponent {
  readonly ODF_SIGN_CHECKER = ODF_SIGN_CHECKER;
  @Input() wasOpen: boolean;
  @Input() shareContract;
  @Input() recreateContract: boolean;
  @Input() agreement: () => void;
  @Input() sharebyEmail: () => void;

  constructor(
    private activeModal: NgbActiveModal,
    private bankIdMobileService: BankIdMobileService,
    private alertService: AlertsService,
  ) {
  }

  closeModal(): void {
    this.activeModal.close()
  }

  toSignUp(): void {
    this.activeModal.close()
    this.agreement()
  }

  toShareByEmail(): void {
    this.activeModal.close()
    this.sharebyEmail()
  }

  goToOdfContract(): void {
    this.bankIdMobileService.getContractLink()
      .subscribe((url: string) => window.open(url, '_blank'),
        ({ data }) => {
          const errorText = data.error === ODF_ERROR.ONE_FLOW_CONTRACT_NOT_FOUND
            ? 'Dear user. You don\'t signed ODF contract yet'
            : 'Something went wrong'

          this.alertService.error(errorText)
        })
  }
}
