<main>
  <h4> {{ 'eSynCloud minimum requirements' | translate }} </h4>
  <p>
    {{
    'eSynCloud is the platform your customer has chosen in order to process your PDF invoices. In order for eSynCloud to
    correctly process your invoices some rules are required. The minimum requirements are as follows:'
    | translate
    }}
  </p>

  <ol>
    <li>
      {{ 'The invoice format has to be PDF, all other formats will be discarded.' | translate }}
    </li>
    <li>
      {{
      'The invoice has to contain the minimum content requirements according to Swedish law, a few of the listed below:'
      | translate
      }}
      <ol type="A">
        <li>
          {{
          'Reference person from buyer side and/or a order reference in your customers specific format has to be present on
          the invoice.'
          | translate
          }}
        </li>
        <li>
          {{ 'The invoice calculations has to be correct on decimal level.' | translate }}
        </li>
        <li> {{ 'Currency code has to present.' | translate }} </li>
        <li>
          {{
          'Taxes on header level has to be present and IF taxes on line level has multiple rates this needs also to be specified.'
          | translate
          }}
        </li>
        <li>
          {{
          'Rounding amount has to be present if invoice is rounded and not greater than decimalsNice to haves. In order for your
          invoices to be correctly processed the PDF should be generated directly (digital) from your system and not scanned.'
          | translate
          }}
        </li>
      </ol>
    </li>
  </ol>

  <div class="d-flex justify-content-center mt-4">
        <span class="text-white btn btn-raised btn-primary" (click)="toTutorial()"> {{ 'Next' | translate }} </span>
  </div>
</main>