<header class="header">
  <div class="header__logo">
    <img src="assets/img/icons/eSynLogo.png" width="120" alt="">
  </div>
  <form class="header__form" [formGroup]="loginForm" (ngSubmit)="submit()">
    <div class="d-flex">
      <fieldset class="form-group">
        <input
          type="text"
          [placeholder]="'Email' | translate"
          class="form-control border-primary sign-up-field"
          id="email"
          formControlName="email"
        >
      </fieldset>
      <fieldset class="form-group">
        <input
          type="password"
          [placeholder]="'Password' | translate"
          class="form-control border-primary sign-up-field"
          id="password"
          formControlName="password"
        >
      </fieldset>
    </div>
    <div class="d-flex align-items-center">
      <a routerLink="/reset-password" class="header__forgot">{{ 'Forgot password' | translate }}?</a>
      <button
        type="submit"
        class="btn login-button btn-round"
        [disabled]="!loginForm.valid"
      >{{ 'Log in' | translate }}</button>
    </div>
  </form>
</header>
