import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IServerResponse } from '../../models/common.models'
import { IEmail, IEmailData } from '../../models/email.model'
@Injectable()

export class EmailService {

  private invoiceUrl = '/invoice-mail'
  private url = '/forward';

  constructor(private http: HttpClient) { }

  forwardInvoice = (id: string): Observable<IServerResponse<IEmail>> => (
    this.http.get(`${this.url}/${id}`).pipe(catchError(this.handleError))
  );

  getEmailsList = (limit?: number, offset?: number, filter?: any): Observable<IServerResponse<IEmailData[]>> => (
    this.http.get(`${this.invoiceUrl}`, { params: { limit, offset, ...filter } })
      .pipe(catchError(this.handleError))
  );

  getEmailDetails = (id: string): Observable<IServerResponse<IEmailData>> => (
    this.http.get(`${this.invoiceUrl}/${id}`).pipe(catchError(this.handleError))
  );

  private handleError(error: any): Promise<any> {
    console.error('An error occurred:', error);
    return Promise.reject(error.message || error);
  }
}
