export enum SPECIFIC_URL {
  USERS_LIST = '/admin/users/list',
}

export const informationUrl = (id, routerUrl) => {
  console.log('informationUrl: ', [id, routerUrl]);
  const [, twoPartUrl, threePartUrl] = routerUrl.split('/');
  const infoUrl = twoPartUrl === 'admin' ? `${twoPartUrl}/${threePartUrl}` : twoPartUrl;

  if (SPECIFIC_URL.USERS_LIST === routerUrl) {
    return `${infoUrl}/details/${id}`;
  } else if (routerUrl.includes('admin/domains/detail/')) {
    return `admin/invoices/detail/${id}`;
  } else {
    return `${infoUrl}/detail/${id}`;
  }
};
