import { RouteInfo } from './sidebar.metadata';

export const client_sidebar = [
  {
    path: '',
    title: 'Invoices',
    icon: 'icon-docs',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      { path: '/invoices/list', title: 'Invoice List', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      {
        path: '/invoices/upload',
        title: 'Upload Invoice',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: '/customers',
    title: 'Customers',
    icon: 'icon-users',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  // {
  //   path: '/emails', title: 'Emails', icon: 'icon-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },
];

export const customer_sidebar = [
  {
    path: '/analytics/dashboard',
    title: 'Dashboard',
    icon: 'ft-airplay',
    submenu: [],
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
  },
  {
    path: '/analytics/suppliers',
    title: 'Suppliers',
    icon: 'ft-users',
    submenu: [],
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
  },
  {
    path: '/analytics/invoices',
    title: 'eSYN PDF Transformed',
    icon: 'ft-layers',
    submenu: [],
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
  },
  {
    path: '/analytics/peppol',
    title: 'eSYN AP Transformed',
    icon: 'ft-layers',
    submenu: [],
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
  },
  { path: '/analytics/users', title: 'Users', icon: 'ft-users', submenu: [], class: '', badge: '', badgeClass: '', isExternalLink: false },
];

export const odf_sidebar = [
  {
    path: '',
    title: 'eSyn Finance',
    icon: 'icon-wallet',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/eSynPay/dashboard',
        title: 'Analytics',
        icon: 'icon-home',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/eSynPay/invoices/list',
        title: 'Invoice List',
        icon: 'icon-docs',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/eSynPay/settings',
        title: 'Settings',
        icon: 'icon-settings',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/eSynPay/faq',
        title: 'FAQ',
        icon: 'icon-question',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
];

export const faq_point = {
  path: '/faq',
  title: 'FAQ',
  icon: 'icon-question',
  class: '',
  badge: '',
  badgeClass: '',
  isExternalLink: false,
  submenu: [],
};

export const getRoutes = (isOdfEnable: boolean, customer_company: any): RouteInfo[] => {
  let routes;
  if (customer_company) {
    routes = customer_sidebar;
  } else if (!customer_company && !isOdfEnable) {
    routes = [...client_sidebar, faq_point];
  } else if (!customer_company && isOdfEnable) {
    routes = [...client_sidebar, ...odf_sidebar, faq_point];
  }

  return routes;
};
