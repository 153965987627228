import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ICustomerInfo } from 'app/admin/companies/detail/components/multi-select/multi-select.component';
import { INewSupplier } from 'app/admin/companies/new/new-supplier.model';
import { ICompany } from 'app/models/company.model';
import { IServerResponse } from 'app/models/failed-sing-up.model';
import { IKerasUser } from 'app/models/keras-user.model';
import { IToggleUser, IUser } from 'app/models/user.model';
import { IAddNewUser } from 'app/models/user.model';

export interface IODFUserSettings {
  _id: string;
  isOdf: boolean;
}

@Injectable()
export class CompanyService {
  private url = '/company';

  private baseUrl = '/company';
  private baseUrlAdmin = '/admin/companies';

  constructor(private http: HttpClient) {}

  getCompany = (id: string): Observable<IServerResponse<ICompany>> =>
    this.http.get<IServerResponse<ICompany>>(`${this.url}/${id}`).pipe(catchError(this.handleError));

  getCompanyByOrgId = (id): Observable<any> => this.http.get<any>(`${this.url}/by-org-id/${id}`).pipe(catchError(this.handleError));

  updateSettings = (id: string, data): Observable<IServerResponse<ICompany>> =>
    this.http.put<IServerResponse<ICompany>>(`${this.url}/${id}/settings`, data).pipe(catchError(this.handleError));

  postCompany = (company: INewSupplier): Observable<ICompany> => this.http.post<ICompany>(this.url, company);

  putCompany = (company: ICompany): Observable<IServerResponse<ICompany>> =>
    this.http.put<IServerResponse<ICompany>>(`${this.url}/${company.id}`, company);

  patchCompany = (company: ICompany): Observable<ICompany> => this.http.patch<ICompany>(`${this.baseUrl}`, company);

  patchCompanyAdmin = (company: ICompany): Observable<ICompany> => this.http.patch<ICompany>(`${this.baseUrlAdmin}/${company.id}`, company);

  updateMany = (companies: any): Observable<any> =>
    this.http.put<any>(`${this.url}/many`, { companies }).pipe(catchError(this.handleError));

  getAllCompanies = (limit?: number | any, offset?: number, filter?: any): Observable<IServerResponse<ICompany[]>> =>
    this.http.get<IServerResponse<ICompany[]>>(this.url, { params: { limit, offset, ...filter } }).pipe(catchError(this.handleError));

  exportCompanies = (filter: any): any =>
    this.http.get(`${this.url}/export`, { params: { ...filter }, responseType: 'blob' }).pipe(
      map((file: Blob) => ({ file })),
      catchError(this.handleError)
    );

  getAllInvalid = (filter): Observable<IServerResponse<any>> =>
    this.http.get<IServerResponse<any>>(`${this.url}/invalid`, { params: { ...filter } }).pipe(catchError(this.handleError));

  addNewUser = (id: string, params: { email: string; name?: string; phone?: string }): Observable<IServerResponse<IAddNewUser>> =>
    this.http.post<IServerResponse<IAddNewUser>>(`${this.url}/${id}/add-new-user`, params);

  addCustomerBySupplierId = (data: ICustomerInfo[], supplierId: string) =>
    this.http.post(`${this.url}/add-customer`, { data, supplierId }).pipe(catchError(this.handleError));

  removeCustomerBySupplierId = (data: string[], supplierId: string): Observable<any> =>
    this.http.post<any>(`${this.url}/remove-customer`, { data, supplierId });

  setAutosale = (id: string, isAutoODF: boolean) => this.http.post(`${this.url}/${id}/set-odf-autosale-settings`, { isAutoODF });

  setCompanyAdmin = (id: string, userId: string) => this.http.post(`${this.url}/${id}/set-odf-admin`, { user: userId });

  toggleUserNotificationOfCompany = (users: IToggleUser[]): Observable<unknown> =>
    this.http.patch<unknown>(`${this.url}/user-notification/`, users).pipe(catchError(this.handleError));

  setOdfUserSettings = (companyId: string, settings: IODFUserSettings[]): Observable<IServerResponse<IUser[]>> =>
    this.http.post<IServerResponse<IUser[]>>(`${this.url}/${companyId}/set-odf-user-settings`, { settings });

  setOdfInterest = (interest?: string, companyId?: string): Observable<IServerResponse<IKerasUser>> =>
    this.http.post<IServerResponse<IKerasUser>>(`${this.url}/${companyId}/set-odf-interest`, { interest });

  setOdfNotification = (id: string, send_odf_notification: boolean) => this.http.patch(`${this.baseUrl}/${id}`, { send_odf_notification });

  private handleError(error: any): Promise<any> {
    console.error('An error occurred:', error);
    return Promise.reject(error.message || error);
  }
}
