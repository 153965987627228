import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IWindowData } from 'app/client/esyn-finance/constants/pop-up.constant';

@Component({
  selector: 'app-odf-base-pop-up',
  templateUrl: './odf-base-pop-up.component.html',
  styleUrls: ['./odf-base-pop-up.component.scss']
})
export class OdfBasePopUpComponent implements OnInit {
  @Input() windowData: IWindowData;
  @Input() isOdfPopUp = true;

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  dispatch(data: string) {
    this.activeModal.close(data)
  }
}
