<div class="background-wrap">
  <div class="container reset-container">
    <div class="row content">
      <div class="col-offset-4 spacer"></div>
      <div class="col-4">
        <div class="wrapper p-2" *ngIf="!resetSuccessful">
          <p class="secondary-text">{{ 'Specify your email' | translate }}</p>
          <fieldset class="form-group">
            <input
              type="email"
              [placeholder]="'Email' | translate"
              class="form-control border-primary form-field"
              id="email"
              [formControl]="emailControl"
            >
            <hr class="input-line m-0">
            <small
              class="m-0 text-muted danger"
              *ngIf="emailControl.touched && emailControl.invalid"
            >{{ 'Email is invalid' | translate }}</small>
          </fieldset>
          <button
            *ngIf="!resetSuccessful"
            type="submit"
            class="btn next-button btn-primary btn-round"
            (click)="checkEmail()"
            [disabled]="!emailControl.valid"
          >{{ 'Submit' | translate }}</button>
        </div>
        <div *ngIf="resetSuccessful" class="wrapper p-2">
          <p class="secondary-text">{{ 'Email with password reset link was sent to your address' | translate }}</p>
          <a class="btn next-button btn-primary btn-round" routerLink="/sign-up">Return</a>
        </div>
      </div>
      <div class="col-offset-4 spacer"></div>
    </div>
  </div>
</div>
