import {AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, NgModule, Output, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

@Directive({
  selector: '[sort-header]'
})
export class SortHeaderDirective implements AfterViewInit, OnDestroy {
  @Input() sortCol: string;
  @Input() active: Subject<any>;
  @Output() onSort = new EventEmitter();
  private direction = 'asc'

  constructor(private el: ElementRef) {
  }

  ngAfterViewInit(): void {
      this.el.nativeElement.classList.add('direction-header')
      this.active.subscribe(col => {
        if (col !== this.sortCol || !col) {
          this.el.nativeElement.classList.remove('direction-asc')
          this.el.nativeElement.classList.remove('direction-desc')
        }
      },
      err => console.log(err)
      )
  }

  @HostListener('click', ['$event.target'])
  onClick() {
    if (this.sortCol) {
      if (this.direction === 'asc') {
        this.el.nativeElement.classList.remove('direction-asc')
        this.el.nativeElement.classList.add('direction-desc')
      } else {
        this.el.nativeElement.classList.remove('direction-desc')
        this.el.nativeElement.classList.add('direction-asc')
      }
      this.direction = this.direction === 'asc' ? 'desc' : 'asc';
      this.onSort.emit({ sortCol: this.sortCol, direction: this.direction });
    }
  }

  ngOnDestroy(): void {
    this.active.unsubscribe();
  }
}


@NgModule({
  declarations: [SortHeaderDirective],
  exports: [SortHeaderDirective]
})

export class SortHeaderModule { }
