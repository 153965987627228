import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { UserService } from 'app/shared/services/user.service';

@Injectable()

export class IsAdminGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.isAdmin().pipe(
      map(({ data }) => {
        if (data.isAdmin) {
          return true
        }

        this.router.navigate(['/']);
      }))
  }
}
