import {Component, OnInit, ViewChild, TemplateRef, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core'
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../auth/auth.service";

@Component({
  selector: 'app-create-service-user',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
})

export class VerifyComponent implements OnInit {

  @Input() user;
  @ViewChild('modalContent') modalContent: TemplateRef<any>;
  public form: FormGroup;
  public intSec = 0;

  constructor(public translate: TranslateService, public activeModal: NgbActiveModal, private authService: AuthService) {
  }

  ngOnInit() {
  }

  public resendVerification() {
    this.intSec = 30;
    this.authService.resendVerification('auth0|' + this.user.Id).subscribe(res => {
      const int = setInterval(() => {
        this.intSec -= 1;
        if (this.intSec === 0) {
          clearInterval(int);
        }
      }, 1000)
    })
  }

}
