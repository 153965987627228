import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { pluck } from 'rxjs/internal/operators/pluck';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IGetPaid } from 'app/models/odf.model';
import { UserService } from 'app/shared/services/user.service';
import { IUser } from 'app/models/user.model';

@Component({
  selector: 'app-bank-alert',
  templateUrl: './bank-alert.component.html',
  styleUrls: ['./bank-alert.component.scss']
})
export class BankAlertComponent implements OnInit {
  form: FormGroup;

  @Input() alertMessage: () => void

  constructor(
    private userService: UserService,
    private activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      personalID: new FormControl('', [Validators.required])
    })
    this.userService.getMyUser().pipe(pluck('data')).subscribe((user: IUser) => {
      this.form.patchValue({email: user.email}, {emitEvent: false});
    })
  }

  value = (valueFromForm: IGetPaid) => {
    this.activeModal.close(valueFromForm)
  };

  toFirstModal = () => {
    this.activeModal.close()
    this.alertMessage()
  }

  closeModal = () => {
    this.activeModal.close()
  }


}
