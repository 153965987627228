<div class="sidebar">
  <div>
        <!-- Sidebar Header starts -->
    <div class="sidebar-header">
      <div class="logo">
              <a [routerLink]="['/']">
                  <div>
                      <img src="assets/img/new-icon.png" width="160"/>
                  </div>
              </a>
      </div>
    </div>
            <!-- Sidebar Header Ends -->

            <!-- Sidebar Content starts -->
    <div class="sidebar-content">
      <div class="nav-container">
          <ul class="navigation">
              <!-- First level menu -->
              <li
                *ngFor="let menuItem of menuItems"
                [ngClass]="[menuItem.class]"
                [routerLinkActive]="menuItem.submenu.length != 0 ? '' : 'active'"
                [routerLinkActiveOptions]="{exact: true}"
              >
                  <a
                    [routerLink]="menuItem.class === '' ? [menuItem.path] : null"
                    *ngIf="!menuItem.isExternalLink"
                  >
                      <i [ngClass]="[menuItem.icon]"></i>
                      <span class="menu-title">{{  menuItem.title | translate}}</span>
                      <div class="dot"></div>
                  </a>
                  <!-- Second level menu -->
                  <ul class="menu-content" *ngIf="menuItem.submenu.length > 0">
                      <li
                        *ngFor="let menuSubItem of menuItem.submenu"
                        [routerLinkActive]="menuSubItem.submenu.length > 0 ? '' : 'active'"
                      >
                          <a
                            [routerLink]="menuSubItem.submenu.length > 0 ? null : [menuSubItem.path]"
                            *ngIf="!menuSubItem.isExternalLink"
                          >
                              <i [ngClass]="[menuSubItem.icon]"></i>
                              <span class="menu-title">{{menuSubItem.title | translate }}</span>
                              <div class="dot"></div>
                          </a>
                          <!-- Third level menu -->
                          <ul class="menu-content" *ngIf="menuSubItem.submenu.length > 0">
                              <li
                                *ngFor="let menuSubsubItem of menuSubItem.submenu"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}"
                              >
                                  <a
                                    [routerLink]="[menuSubsubItem.path]"
                                    *ngIf="!menuSubsubItem.isExternalLink"
                                    >
                                      <i [ngClass]="[menuSubsubItem.icon]"></i>
                                      <span class="menu-title">{{menuSubsubItem.title | translate}}</span>
                                  </a>
                              </li>
                          </ul>
                      </li>
                  </ul>
              </li>
          </ul>
        </div>
      </div>
  </div>

    <div *ngIf="isAdmin" class="text-admin-panel">
       {{ 'admin panel' | translate }}
    </div>
    <!-- <div *ngIf="!isAdmin" class="text-admin-panel">
      {{'analytics' | translate }}
    </div> -->
</div>
