import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OdfStatuses } from 'app/models/odf.model';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-invoice-upload-alert',
  templateUrl: './invoice-upload-alert.component.html',
  styleUrls: ['./invoice-upload-alert.component.scss']
})
export class InvoiceUploadAlertComponent implements OnInit {

  isUserOdf: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.userService.getMyUser().subscribe(({data}) => {
     this.isUserOdf = data.odfInformation.status === OdfStatuses.ELIGIBLE;
    })
  }

  close(): void {
    this.activeModal.close();
  }
}
