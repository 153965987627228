import { Component, OnInit, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { OcrmlService } from 'app/shared/services/ocrml.service';
import { IAddressInformation, IAccountDetails } from '../../../../models/common.models';
import { AlertsService } from 'app/shared/services/alerts.service';
import { IDecodeResponse } from 'app/shared/auth/auth.service';

@Component({
  selector: 'app-verify-suppliers-details',
  templateUrl: './verify-suppliers-details.component.html',
  styleUrls: ['./verify-suppliers-details.component.scss']
})
export class VerifySuppliersDetailsComponent implements OnInit, OnDestroy {
  @Input() accountDetails: IAccountDetails = {} as IAccountDetails;
  @Input() addressInformation: IAddressInformation = {} as IAddressInformation;
  @Input() decodeInfo: IDecodeResponse = {} as IDecodeResponse;
  @Input() isNewUser: boolean;

  @Output() next = new EventEmitter();

  addressInfoForm: FormGroup;
  accountDetailsForm: FormGroup;
  uploadPDFSubscription: Subscription;
  loading = false;

  constructor(
    private fb: FormBuilder,
    private ocrmlService: OcrmlService,
    private alertService: AlertsService
  ) {}

  ngOnInit() {
    this.addressInfoForm = this.fb.group({
      street: [{
        value: this.addressInformation.street
          || (this.isNewUser && this.decodeInfo ? this.decodeInfo.invoiceBaseInfo.supplier_address.street : ''),
        disabled: !this.isNewUser },
      Validators.required],
      zip: [{
        value: this.addressInformation.zip
          || (this.isNewUser && this.decodeInfo ? this.decodeInfo.invoiceBaseInfo.supplier_address.zip : ''),
        disabled: !this.isNewUser },
      Validators.required],
      city: [{
        value: this.addressInformation.city
          || (this.isNewUser && this.decodeInfo ? this.decodeInfo.invoiceBaseInfo.supplier_address.city : ''),
        disabled: !this.isNewUser },
      Validators.required],
      country: [{
        value: this.addressInformation.country
          || (this.isNewUser && this.decodeInfo ? this.decodeInfo.invoiceBaseInfo.supplier_address.address_line : ''),
        disabled: !this.isNewUser },
      Validators.required],
    })

    this.accountDetailsForm = this.fb.group({
      local: [{
        value: this.accountDetails.local
          || (this.isNewUser && this.decodeInfo ? this.decodeInfo.invoiceBaseInfo.local : ''),
        disabled: !this.isNewUser },
      ],
      iban: [{
        value: this.accountDetails.iban
          || (this.isNewUser && this.decodeInfo ? this.decodeInfo.invoiceBaseInfo.iban : ''),
        disabled: !this.isNewUser }
      ],
      bicSwift: [{
        value: this.accountDetails.bic
          || (this.isNewUser && this.decodeInfo ? this.decodeInfo.invoiceBaseInfo.swift : ''),
        disabled: !this.isNewUser }
      ],
    })
  }

  onNext() {
    this.next.emit({
      addressInformation: this.addressInfoForm.value,
      accountDetails: this.accountDetailsForm.value
    });
  }


  handleFileInput(file) {
    if (file[0].name.includes('.pdf')) {
      this.loading = true;
      this.uploadPDFSubscription = this.ocrmlService.uploadInvoiceAndReceiveData(file[0])
        .subscribe((data: any) => {
          const info = JSON.parse(data._body);
          const { iban, local, swift, supplier_address } = info;
          this.setFormValues({ accounts: { iban, local, bic: swift }, address: supplier_address })
          this.alertService.success('Your invoice has been parsed! Please check and verify the details');
          this.loading = false;
        })
    } else {
      this.alertService.error('Invoice should be pdf format. Please try again')
      this.loading = false;
    }
  }

  setFormValues(info: { address: IAddressInformation, accounts: IAccountDetails }) {
    this.iban.setValue(info.accounts.iban || this.iban.value);
    this.local.setValue(info.accounts.local || this.local.value);
    this.bicSwift.setValue(info.accounts.bic || this.bicSwift.value);
    this.city.setValue(info.address.city || this.city.value);
    this.zip.setValue(info.address.zip || this.zip.value);
    this.country.setValue(info.address.country || this.country.value);
    this.street.setValue(info.address.street || this.street.value);
  }

  ngOnDestroy() {
    if (this.uploadPDFSubscription) {
      this.uploadPDFSubscription.unsubscribe();
    }
  }

  get street() { return this.addressInfoForm.get('street') };
  get zip() { return this.addressInfoForm.get('zip') };
  get city() { return this.addressInfoForm.get('city') };
  get country() { return this.addressInfoForm.get('country') };

  get local() { return this.accountDetailsForm.get('local') };
  get iban() { return this.accountDetailsForm.get('iban') };
  get bicSwift() { return this.accountDetailsForm.get('bicSwift') };

}
