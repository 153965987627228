<div
  class="autocompleter"
  (clickOutside)="isOpen = false"
>
  <input
    type="text"
    autocomplete="off"
    [formControl]="controlValue"
    [placeholder]="placeholder | translate"
    (focus)="isOpen = true"
  />
  <div
    class="autocompleter__list"
    *ngIf="isOpen && controlValue.value || isOpen && isEmpty"
  >
    <div
      class="autocompleter__list-item"
      *ngFor="let item of autcompoleteData; let index = index"
      [ngClass]="{'autocompleter__list-item-active': preselectedIndex === index}"
      (click)="selectItem(item)"
    >{{ item.name }}</div>
    <div *ngIf="isOpen && !autcompoleteData?.length" class="autocompleter__list-item">Not found</div>
  </div>
</div>
