
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ICustomer, ICustomerRequest } from '../../models/customer.model';
import { IServerResponse } from '../../models/common.models';
@Injectable()

export class CustomerService {

  private url = '/customers';
  private adminUrl = '/admin/customers';

  constructor(private http: HttpClient) { }

  // Requests for admin role
  getAdminCustomers = (limit?: number | any, offset?: number, filter?: any): Observable<IServerResponse<ICustomer[]>> => (
    this.http.get<IServerResponse<ICustomer[]>>(this.adminUrl, { params: { limit, offset, ...filter } })
      .pipe(catchError(this.handleError))
  );

  exportAdminCustomers = (filter: any): any => (
    this.http.get(`${this.adminUrl}/export/csv`, { params: { ...filter }, responseType: 'blob' }).pipe(
      map((file: Blob) => ({ file })),
      catchError(this.handleError))
  );

  getAdminCustomer = (id): Observable<ICustomer> => (
    this.http.get<ICustomer>(`${this.adminUrl}/${id}`).pipe(catchError(this.handleError))
  );

  postCustomer = (customer: ICustomerRequest): Observable<any> => (
    this.http.post(this.adminUrl, customer).pipe(catchError(this.handleError))
  );

  putCustomer = (customer: ICustomerRequest): Observable<any> => (
    this.http.put(`${this.adminUrl}/${customer.id}`, customer)
      .pipe(catchError(this.handleError))
  );

  getAllCustomers = (name: string = ''): Observable<ICustomer[]> => (
    this.http.get<ICustomer[]>(`${this.adminUrl}/all/`, { params: { name } })
      .pipe(catchError(this.handleError))
  );

  // Requests for user role
  getCustomers = (limit?: number | any, offset?: number, filter?: any): Observable<IServerResponse<any[]>> => (
    this.http.get<IServerResponse<ICustomer[]>>(this.url, { params: { limit, offset, ...filter } })
      .pipe(catchError(this.handleError))
  );

  exportCustomers = (filter: any): any => (
    this.http.get(`${this.url}/export/csv`, { params: { ...filter }, responseType: 'blob' }).pipe(
      map((file: Blob) => ({ file })),
      catchError(this.handleError))
  );

  getCustomer = (id): Observable<ICustomer> => (
    this.http.get<ICustomer>(`${this.url}/${id}`).pipe(catchError(this.handleError))
  );

  addChildrenCompany = (parent: string, child: string): Observable<any> => (
    this.http.post<any>(`${this.url}/child`, { parent, child })
      .pipe(catchError(this.handleError))
  );

  removeFromChildren = (parent: string, child: string): Observable<any> => (
    this.http.post<any>(`${this.url}/child/remove`, { parent, child })
      .pipe(catchError(this.handleError))
  );

  private handleError(error: any): Promise<any> {
    console.error('An error occurred:', error);
    return Promise.reject(error.message || error);
  }

}
