<div class="alternative-text" *ngIf="loading">
  <img src="assets/img/table-loading.svg" class="alternative-text" width="50" height="50">
</div>

<div class="alternative-text" [hidden]="data?.length || loading">
  <h6>
    {{ messageForEmptyTable | translate }}
  </h6>
</div>
<table class="table table-striped table-desktop">
  <thead [hidden]="!data?.length || loading">
    <tr>
      <th *ngFor="let headTable of pointOfTable" sort-header [sortCol]="headTable.sortBy"
        [active]="filterService.activeCol" (onSort)="sortCol($event)" class="table-header">
        {{ headTable.title | translate }}
      </th>
    </tr>
  </thead>
  <tbody [hidden]="!data?.length || loading">
    <tr *ngFor="let bodyTable of data;">
      <td *ngFor="let result of pointOfTable" class="table-align">
        <container-element [ngSwitch]="result.type">
          <some-element *ngSwitchCase="contentTableTypes.TEXT">
            <p> {{ (result?.value | objectName: bodyTable : result?.defaultValue : result?.otherValue) }} </p>
          </some-element>
          <some-element *ngSwitchCase="contentTableTypes.FileNamePipe">
            <p> {{ (result.value | objectName: bodyTable) || (result.otherValue | objectName: bodyTable | fileName) }}
            </p>
          </some-element>
          <some-element *ngSwitchCase="contentTableTypes.FailedCasePipe">
            <p> {{ result.value | objectName: bodyTable | failedCase }} </p>
          </some-element>
          <some-element *ngSwitchCase="contentTableTypes.FailedCheckPipe">
            <p> {{ result.value | objectName: bodyTable | failedCheck }} </p>
          </some-element>
          <some-element *ngSwitchCase="contentTableTypes.EmailPipe">
            <p> {{ result.value | objectName: bodyTable | senderEmails }} </p>
          </some-element>
          <some-element *ngSwitchCase="contentTableTypes.DATE">
            {{ result?.value | objectName: bodyTable | date }}
          </some-element>
          <some-element *ngSwitchCase="contentTableTypes.AddressPipe">
            {{ result?.value | objectName: bodyTable | address }}
          </some-element>
          <some-element *ngSwitchCase="contentTableTypes.ICONreverse">
            <div *ngIf="bodyTable[result?.value]; else successBlock" class="text-warning ft-alert-triangle"></div>
            <ng-template #successBlock>
              <div class="text-success ft-check"></div>
            </ng-template>
          </some-element>
          <some-element *ngSwitchCase="contentTableTypes.ICON">
            <div *ngIf="bodyTable[result?.value]; else dangerBlock" class="text-success ft-check text-center"></div>
            <ng-template #dangerBlock>
              <div class="text-danger text-center ft-alert-triangle"></div>
            </ng-template>
          </some-element>
          <some-element *ngSwitchCase="contentTableTypes.IconStateSupplier">
            <div *ngIf="bodyTable.prio && bodyTable.days_since_last_invoice >= 30; else elseBlock"
              [ngClass]="{'text-danger': bodyTable.days_since_last_invoice >= 60, 'text-warning': bodyTable.days_since_last_invoice >= 30}"
              [ngbPopover]="popContent" triggers="mouseenter:mouseleave"
              [popoverTitle]="bodyTable.days_since_last_invoice >= 60 ? '60 days warning - Inactivity' : '30 days warning - Inactivity'"
              class="ft-alert-triangle text-center">
            </div>
            <ng-template #popContent>
              <div>{{ 'Customer' | translate }}: {{ bodyTable?.lastInvoice?.customer?.name }}</div>
              <div>{{ 'Invoice ID' | translate }}: {{ bodyTable?.lastInvoice?.invoice_id }}</div>
              <div>{{ 'Last Invoice' | translate }}: {{ bodyTable?.lastInvoice?.created_at | date }}</div>
              <div>{{ 'Sign up date' | translate }}: {{ bodyTable?.lastInvoice?.customer?.created_at | date }}</div>
            </ng-template>
            <ng-template #elseBlock>
              <div class="text-success text-center ft-check"></div>
            </ng-template>
          </some-element>
          <some-element *ngSwitchCase="contentTableTypes.InvoiceProgres">
            <app-invoices-progress [value]="bodyTable.status"></app-invoices-progress>
          </some-element>
          <some-element *ngSwitchCase="contentTableTypes.PeppolProgres">
            <app-invoices-progress [value]="bodyTable.status" [type]="TypeOfProgressBar.PeppolStatuses"></app-invoices-progress>
          </some-element>
          <some-element *ngSwitchCase="contentTableTypes.Button">
            <button [ngClass]="result.class" (click)="emitDataTwo(bodyTable)">{{ result.buttonText | translate
              }}</button>
          </some-element>
          <some-element *ngSwitchCase="contentTableTypes.multiButton">
            <div class="btn-group">
              <button *ngIf="!bodyTable.auth0" (click)="emitDataOne(bodyTable._id)" class="btn btn-raised btn-danger m-0">
                {{ 'Delete' | translate }}
              </button>
              <button *ngIf="bodyTable.type === userTypes.openChannel" (click)="emitDataTwo(bodyTable._id)"
                class="btn btn-raised btn-success m-0">
                {{ 'Resend' | translate }}
              </button>
            </div>
            </some-element>
            <some-element *ngSwitchCase="contentTableTypes.multiButtonUserFailed">
              <button class="btn btn-raised btn-primary" [disabled]="bodyTable?.isResolve"
                (click)="emitDataOne({id: bodyTable?._id, event: $event})">{{ (bodyTable?.isResolve ? 'Resolved' : 'Mark
              as resolved') | translate }}
              </button>
              </some-element>
              <some-element *ngSwitchCase="contentTableTypes.INFO">
                <p (click)="infoUrl(bodyTable._id)" *ngIf="curentlyRoute !== '/customers'; else customerRequirementsAlert"
                  class="info-link-wrapper">
                  <i class="icon-info info-link"></i>
                </p>
                <ng-template #customerRequirementsAlert>
                  <p (click)="modalCustomerInfo(bodyTable)">
                    <i class="icon-info info-link"></i>
                  </p>
                </ng-template>
              </some-element>
              <some-element *ngSwitchCase="contentTableTypes.CheckboxChange">
                <input type="checkbox" (change)="rowSelect(bodyTable._id, $event.target.checked)" class="mb-3">
              </some-element>
              <some-element *ngSwitchCase="contentTableTypes.CheckboxAutomated">
                <input type="checkbox" [(ngModel)]="bodyTable[result?.value]"
                  (change)="checkParam(bodyTable, $event.target.checked, result.checkParamString)" [checked]="bodyTable[result.value]"
                  [disabled]="checkDisabled(bodyTable._id)" class="mb-3">
              </some-element>
              <some-element *ngSwitchCase="contentTableTypes.stageOfFailedCase">
                {{ stageOfFailedCase(bodyTable[result?.value]) }}
              </some-element>
              </container-element>
              </td>
              </tr>
              </tbody>
              </table>
