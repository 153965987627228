import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { enableProdMode } from '@angular/core';

import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { FullLayoutComponent } from './layouts/full/full-layout.component';

import { ADMIN_ROUTES } from './shared/routes/admin-layout.routes';
import { CONTENT_ROUTES } from './shared/routes/content-layout.routes';
import { Full_ROUTES } from './shared/routes/full-layout.routes';

import { AuthGuard } from './shared/auth/auth-guard.service';
import { IsAdminGuard } from './shared/isAdmin/isAdmin-guard.service';

import { RedirectGuard } from './guards/redirect/redirect.guard';
import { PasswordResetComponent } from './shared/password-reset/password-reset.component';
import { SignUpComponent } from './shared/sign-up/sign-up.component';

// enableProdMode();

const appRoutes: Routes = [
  { path: '', redirectTo: 'invoices/list', pathMatch: 'full' },
  { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES },
  {
    path: '',
    component: FullLayoutComponent,
    data: { title: 'full Views' },
    children: Full_ROUTES,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard, RedirectGuard],
  },
  { path: 'admin', redirectTo: 'admin/dashboard', pathMatch: 'full' },
  {
    path: 'admin',
    component: FullLayoutComponent,
    data: { title: 'admin Views' },
    children: ADMIN_ROUTES,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard, IsAdminGuard],
  },
  { path: 'sign-up', component: SignUpComponent },
  { path: 'reset-password', component: PasswordResetComponent },
  { path: '**', redirectTo: 'error', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
