import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IServerResponse } from '../../models/common.models'
import { ICompanyPublicInfo } from '../../models/findsy.model'
@Injectable()

export class FindsyService {

  private url = '/findsy';

  constructor(private http: HttpClient) { }

  getCompanyPublicInfo = (id: string, email: string): Observable<IServerResponse<ICompanyPublicInfo>> => (
    this.http.get<IServerResponse<ICompanyPublicInfo>>(`${this.url}/company/publicInfo?org_id=${id}&email=${email}`)
      .pipe(catchError(this.handleError))
  );

  private handleError(error: any): Promise<any> {
    console.error('An error occurred:', error);
    return Promise.reject(error.message || error);
  }
}
